import React, { useEffect, lazy, Suspense } from "react";
import "../assets/css/main-grid.css";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import welcome from "../assets/images/welcome.svg";
import { Paper, ProtectedRoute, Loader, NothingHere, Help, Navbar, Sidebar } from "../components";
import {
	selectLoggedInUser,
	useAppSelector,
	getPatients,
	getPlatformUsers,
	getVisits,
	getBookings,
	useAppDispatch,
} from "../appStore";
import MedicalFile from "./prescription/File";
import { ConfigProvider } from "antd";
const Booking = lazy(() => import("./booking"));
const Call = lazy(() => import("./call"));
// const Document = lazy(() => import("./document/Document"));
const Prescription = lazy(() => import("./prescription"));
const Patient = lazy(() => import("./patient"));
const Resources = lazy(() => import("./resources"));
const Setting = lazy(() => import("./settings"));
const Visit = lazy(() => import("./visit/"));
const Dashboard = lazy(() => import("./Dashboard"));

const Main = () => {
	const miniPlayer = useAppSelector((state) => state.layout.miniPlayer);
	const match = useRouteMatch("/main/call");
	let dispatch = useAppDispatch();
	const user = useAppSelector(selectLoggedInUser);
	let isAdmin = user.roles[0] === "ROLE_ADMIN";
	let isTechnician = user.roles[0] === "ROLE_TECHNICIAN";
	let isDoctor = user.roles[0] === "ROLE_DOCTOR";
	let isGrader = user.roles[0] === "FIRST_GRADER"; //grader

	useEffect(() => {
		dispatch(getBookings());
		dispatch(getPatients());
		dispatch(getPlatformUsers());
		dispatch(getVisits());
	}, []);
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#046638",
				},
				components: {
					Collapse: {
						headerBg: "transparent",
					},
				},
			}}>
			<div className="MainApp">
				<Suspense fallback={""}>
					<Sidebar />
				</Suspense>
				{/* <Suspense fallback={""}>
        <Navbar />
      </Suspense> */}
				<div className="full-grid">
					<Switch>
						<ProtectedRoute path="/main/booking/" component={Booking} condition={isAdmin || isTechnician || isDoctor} />
						<ProtectedRoute
							path="/main/patient/"
							component={Patient}
							condition={isAdmin || isTechnician || isDoctor || isGrader}
						/>
						<ProtectedRoute path="/main/visit/" component={Visit} condition={isAdmin || isDoctor || isGrader} />

						<ProtectedRoute path="/main/medical/file/:id" component={MedicalFile} condition={isAdmin} />

						<ProtectedRoute path="/main/medical/" component={Prescription} condition={isAdmin || isDoctor} />
						<ProtectedRoute path="/main/resources/" component={Resources} condition={isAdmin} />
						<ProtectedRoute path="/main/users/" component={Setting} condition={isAdmin} />

						<Route path="/main/help">
							<Suspense fallback={<Loader />}>
								<Help />
							</Suspense>
						</Route>
						<Route path="/main" exact>
							{isDoctor ? (
								<Suspense fallback={<Loader />}>
									<Booking />
								</Suspense>
							) : (
								<Suspense fallback={<Loader />}>
									<Dashboard />
								</Suspense>
							)}
						</Route>
						{!match && (
							<Route path="*">
								<Suspense fallback={<Loader />}>
									<NothingHere />
								</Suspense>
							</Route>
						)}
					</Switch>
				</div>
				{/* {(match || miniPlayer) && <Call />} */}
			</div>
		</ConfigProvider>
	);
};

const Welcome = () => {
	const usernameState = useAppSelector(selectLoggedInUser).username;
	return (
		<Paper className="text-center show">
			<h1>Welcome to your Dashboard </h1>
			<h2>{usernameState}</h2>
			<p>Use the Sidebar to navigate the application</p>
			<p className="text-muted">
				Need Assistance? <a href="/help">Click here</a>
			</p>
			<p className="text-muted">
				Found a bug? <a href="mailto:mimiriath@gmail.com">Email the Developers</a>
			</p>
			<div className="row">
				<img src={welcome} className="img-responsive col-6 offset-3" style={{ maxHeight: "50vh" }} alt="" />
			</div>
		</Paper>
	);
};

export default Main;
