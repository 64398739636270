import React, { Suspense } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import Loader from "../../components/pageComponents/Loader";

const ProtectedRoute = ({
	component: Component,
	condition,
	redirect = "/login",
	...restOfProps
}: RouteProps & {
	component: React.FunctionComponent<any>;
	condition: boolean;
	redirect?: string;
}) => {
	return (
		<Route
			{...restOfProps}
			render={(props) =>
				// condition ? <Component {...props} /> : <Redirect to="/login" />
				condition ? (
					<Suspense fallback={""}>
						<Component {...props} />
					</Suspense>
				) : (
					<Redirect to={redirect} />
				)
			}
		/>
	);
};

export default ProtectedRoute;
