import React from "react";
import { Select, InputProps, SelectProps } from "antd";

//
const Addon = (props: SelectProps & { type: "height" | "weight" | "temp" | "RBG" | "text" | "hba1C" }) => {
	const Option = Select;

	switch (props.type) {
		case "height":
			return (
				<Select defaultValue="cm">
					<Option value="cm">cm</Option>
					<Option value="in">in</Option>
					<Option value="ft">ft</Option>
				</Select>
			);

		case "weight":
			return (
				<Select defaultValue="kg">
					<Option value="kg">kg</Option>
					<Option value="lb">lb</Option>
				</Select>
			);

		case "temp":
			return (
				<Select defaultValue="°C">
					<Option value="°C">°C</Option>
					<Option value="°F">°F</Option>
				</Select>
			);
		case "RBG":
			return (
				<Select defaultValue="mg/dl">
					<Option value="mg/dl">mg/dl</Option>
					<Option value="mmol/l">mmol/l</Option>
				</Select>
			);
		case "hba1C":
			return (
				<Select defaultValue="mg/dl">
					<Option value="mg/dl">mg/dl</Option>
					<Option value="mmol/l">mmol/l</Option>
				</Select>
			);
		default:
			return <></>;
	}
};

export default Addon;
