import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { selectAllVisits } from "../visitSlice/selectors";
import { selectAllPatients } from "../patientSlice/selectors";

// select all
export const selectAllBookings = createSelector(
  [(state: RootState) => state?.bookings?.all],
  (bookings) => bookings
);

export const selectActiveBooking = createSelector(
  [(state: RootState) => state.bookings?.active],
  (active) => active
);
export const selectLatestBooking = createSelector(
  [(state: RootState) => state.bookings?.latest],
  (active) => active
);
export const selectUnusedBookings = createSelector(
  [selectAllBookings, selectAllVisits],
  (bookings, visits) =>
    bookings?.filter((item) => {
      let index = visits.findIndex(
        (visit) => visit.bookingRefNumber == item.bookingRef
      );
      return index === -1 && item?.bookingRef !== "";
    })
);

export const selectUnusedBookingsWithPatient = createSelector(
  [selectUnusedBookings, selectAllPatients],
  (bookings, patient) => {
    return bookings.map((booking) => {
      return {
        ...booking,
        ...patient.find((pa) => pa.idNumber == booking.patientNationalID),
      };
    });
  }
);
// select single by param
export const selectBookingByRef = createSelector(
  [selectAllBookings, (state: RootState, ref) => ref],
  (bookings, ref) =>
    bookings
      ?.filter((item) => item.bookingRef !== "")
      .find((item) => item.bookingRef == ref)
);
export const selectUnusedBookingByRef = createSelector(
  [selectUnusedBookings, (state: RootState, ref) => ref],
  (bookings, ref) => {
    return bookings?.find((item) => item?.bookingRef == ref);
  }
);

export const selectBookingById = createSelector(
  [selectAllBookings, (state: RootState, id) => id],
  (bookings, id) => bookings?.find((item) => item.id == id)
);
export const selectBookingByPatientID = createSelector(
  [selectAllBookings, (state: RootState, id) => id],
  (bookings, id) => bookings?.find((item) => item.patientNationalID == id)
);

// select bookings by filter param
export const selectBookingsByDoctor = createSelector(
  [selectAllBookings, (state: RootState, username) => username],
  (bookings, username) => bookings?.filter((item) => item.doctor == username)
);
export const selectBookingsByClinic = createSelector(
  [selectAllBookings, (state: RootState, clinic) => clinic],
  (bookings, clinic) => bookings?.filter((item) => item.clinic == clinic)
);
export const selectBookingsByTechnician = createSelector(
  [selectAllBookings, (state: RootState, tech) => tech],
  (bookings, tech) => bookings?.filter((item) => item.attendingTechName == tech)
);

export const selectFilteredBookingsByQuery = createSelector(
  [
    selectAllBookings,
    selectAllPatients,
    selectAllVisits,
    (state, rawQuery: string) => rawQuery,
  ],
  (bookings, patients, visits, rawQuery) => {
    let arr: string[] = [];

    visits.forEach((v) => arr.push(v.bookingRefNumber));

    const query = rawQuery.trim();

    return bookings
      .filter((data) => {
        return data.patientNationalID === query || data.bookingRef === query;
      })
      .map((a) => {
        const patient = patients.find(
          (b) => b.idNumber == query || b.idNumber == a.patientNationalID
        );
        return {
          ...a,
          ...patient,
          id: a.id,
          patientId: patient?.id,
        };
      })
      .filter((c) => {
        return !arr.includes(c.bookingRef);
      });
  }
);
