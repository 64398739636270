import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_CLIENT } from "../../axiosInstance";
import { toast } from "react-toastify";
import { PatientDataType, RemidioPatientExamType } from "../../../utils";
import { store } from "../../store";

export const getLatestPatientExam = createAsyncThunk(
	"camera/get_latest_patient_exam",
	async (patientIdNumber: string) => {
		try {
			toast.loading("We are getting the images from your registered camera", {
				containerId: "toast",
				position: "bottom-center",
			});
			let response = await AXIOS_CLIENT.get(`patients/remidioPatient/${patientIdNumber}`);
			return response.data["images"]["fopImages"]["STANDARD"];
		} catch (err) {
			throw err;
		} finally {
			toast.dismiss();
		}
	}
);

// export const getLatestPatientExam = createAsyncThunk(
//   "camera/get_latest_patient_exam",
//   async (patientIdNumber: string) => {
//     try {
//       await AXIOS_CLIENT.get(`patients/remidioPatient/${patientIdNumber}`);
//     } catch (err) {
//       throw err;
//     } finally {
//       let response = await AXIOS_CLIENT.get(`documents/download/${patientIdNumber}/for/PATIENT`);
//       return response.data;
//     }
//   }
// );
