import React, { useEffect, useState, useRef } from "react";
// import { useFetch } from "../../../utils/CustomHooks";
import { toast, Zoom, Id } from "react-toastify";
import { IMAGE_PREVIEW_URL, FileType } from "../../utils";
import { XCircle } from "react-bootstrap-icons";
import noPreview from "../../assets/images/No-preview-available.png";
import { AXIOS_CLIENT } from "../../appStore/axiosInstance";
import { AxiosError } from "axios";
import { Image } from "antd";

/**
 * @Todo implement redux here
 * @param param0
 * @returns
 */
const ImgPreview = ({ type, id, file = false }: { type: string; id: string | number | undefined; file?: boolean }) => {
	const [iframeUrl, setIframeUrl] = useState("");
	const [imgList, setImgList] = useState<FileType[]>([]);
	const [idState, setIdState] = useState(id);
	// const { get, data } = useFetch(`/documents/download`);
	// const data: FileType[] = [];
	const [data, setData] = useState<FileType[]>([]);
	const viewer = useRef<Id | undefined>(undefined);

	useEffect(() => {
		setIdState(id);
	}, [id]);

	useEffect(() => {
		fetch();
	}, [idState]);

	useEffect(() => {
		if (iframeUrl) viewer.current = displaySingle();

		return () => {
			toast.dismiss(viewer.current);
		};
	}, [iframeUrl]);

	useEffect(() => {
		Array.isArray(data) && setImgList(data);
	}, [data]);

	// fetch the files
	const fetch = async () => {
		try {
			let response = await AXIOS_CLIENT.get(`documents/download/${idState}/for/${type}`);
			setData(response.data);
		} catch (err) {
			toast.error(`Failed to fetch ${type.toLowerCase()} files`);
		}
	};

	const displaySingle = () => {
		return toast(<ImgViewer iframeUrl={iframeUrl} />, {
			autoClose: false,
			onClose: close,
			position: "top-center",
			transition: Zoom,
			draggable: false,
			containerId: "image-preview",
			closeOnClick: false,
			closeButton: CloseButton as any,
		});
	};

	const close = () => {
		setIframeUrl("");
	};

	return (
		<>
			<div className="row my-4 image-preview-row">
				{imgList.map((img) => {
					if (!img?.contentType.includes("image")) {
						return (
							<div className="col-md-3 col-6 image-preview-row-item" key={img?.doc_key}>
								<a href={`${IMAGE_PREVIEW_URL}${img?.doc_key}`} target="_blank">
									<img src={noPreview} height="100px" />
									<p>{img?.filename}</p>
								</a>
							</div>
						);
					}
					return (
						<div className={`col-6 image-preview-row-item ${!file && "col-md-2"}`} key={img?.doc_key}>
							{/* <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();

                  setIframeUrl(`${IMAGE_PREVIEW_URL}${img?.doc_key}`);
                }}
              >
                <img
                  src={`${IMAGE_PREVIEW_URL}${img?.doc_key}`}
                  height={file ? "100%" : "100px"}
                  width={file ? "95%" : ""}
                />
                {!file && <p>{img?.filename}</p>}
              </a> */}
							<Image src={`${IMAGE_PREVIEW_URL}${img?.doc_key}`} width={file ? "95%" : ""} />
						</div>
					);
				})}
			</div>
		</>
	);
};

const ImgViewer = ({ iframeUrl }: { iframeUrl: string }) => {
	const [scaleValue, setScaleValue] = useState(1);

	const increment = () => {
		scaleValue < 3 && setScaleValue((prevState) => prevState + 0.1);
	};

	const decrement = () => {
		scaleValue > 1 && setScaleValue((prevState) => prevState - 0.1);
	};

	return (
		<div className="image-preview-frame">
			<div className="image-preview-zoom-controls" title="zoom">
				<button onClick={decrement}>-</button>
				<button onClick={increment}>+</button>
				<p>{Math.round(scaleValue * 100) + "%"}</p>
			</div>

			<img
				src={iframeUrl}
				className="image-preview-image"
				style={{
					transform: `scale(${scaleValue})`,
					transformOrigin: "top left",
				}}></img>
		</div>
	);
};

const CloseButton = ({ closeToast }: { closeToast: () => {} }) => {
	return (
		<XCircle
			onClick={closeToast}
			style={{
				width: "1.5rem",
				height: "1.5rem",
				color: "red",
				fontWeight: "700",
			}}
		/>
	);
};

export default ImgPreview;
