import axios from "axios";
import { RootState, store } from "./store";

export const baseURL = "https://aliveapp.azurewebsites.net/";

export const AXIOS_CLIENT = axios.create({
	baseURL,
	withCredentials: true,
	//   credentials: "include",
	headers: {
		"Access-Control-Max-Age": 8000,
		Authorization: ``,
		accept: "*/*",
	},
});

AXIOS_CLIENT.interceptors.request.use((config) => {
	if (config.headers) {
		let state: RootState = store.getState();
		config.headers["Authorization"] = `Bearer ${state.user.token}`;
	}
	return config;
});
