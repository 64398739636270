import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientDataType, DataSliceType } from "../../../utils";
import {
  createPatient,
  deletePatient,
  getPatients,
  updatePatient,
} from "./thunks";

const initialState: DataSliceType<PatientDataType> = {
  latest: null,
  active: {
    address: "",
    avatar: "",
    dob: "",
    email: "",
    firstname: "",
    gender: "",
    id: 0,
    idNumber: "",
    lastname: "",
    maritalStatus: "",
    mobile: "",
    occupation: "",
    title: "",
  },
  all: [],
};

const Slice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setLatestPatient: (
      state,
      action: PayloadAction<PatientDataType | null>
    ) => {
      state.latest = action.payload;
    },
    setActivePatient: (
      state,
      action: PayloadAction<PatientDataType | undefined>
    ) => {
      state.active = action.payload ? action.payload : initialState.active;
    },
    updateActivePatient: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | number;
      }>
    ) => {
      //  @ts-ignore
      state.active[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatients.fulfilled, (state, action) => {
        if (action.payload) {
          state.all = action.payload;
        }
        return state;
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        if (action.payload) {
          state.latest = action.payload;
          state.active = initialState.active;
          state.all.push(action.payload);
        }
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        if (action.payload) {
          state.latest = action.payload;
          let index = state.all.findIndex(
            (item) => item.idNumber === action.payload.idNumber
          );
          state.all[index] = action.payload;
        }
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        if (action.payload) {
          state.all = state.all.filter((item) => item.id !== action.payload);
          state.latest = initialState.latest;
        }
      });
  },
});

export const { updateActivePatient, setLatestPatient, setActivePatient } =
  Slice.actions;

export default Slice.reducer;
