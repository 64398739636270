import React from 'react'


const NothingHere = () => {
  return (
    <div className="row text-center">
      <p className="h3 mt-3">There's Nothing Here </p>

    </div>
  )
}

export default NothingHere