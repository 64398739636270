import { useEffect, useState } from "react";
import { getAllClinics, selectAllClinics, useAppDispatch, useAppSelector } from "../../appStore";
import { AsyncTable } from "../../components";
import { ClinicDataType, PatientDataType, removeResolvedBookings, UserDataType } from "../../utils";

const initState = [
	{
		name: "",
		address: "",
	},
];

const AllClinics = () => {
	const dispatch = useAppDispatch();
	const clinics = useAppSelector(selectAllClinics);
	const [TableData, setTableData] = useState<ClinicDataType[]>([]);

	useEffect(() => {
		dispatch(getAllClinics());
	}, []);

	useEffect(() => {
		setTableData(clinics);
	}, [clinics]);

	return (
		<>
			<h3>Super Admin : All clinics</h3>
			<AsyncTable propData={TableData.length ? TableData : initState} linked />
		</>
	);
};

export default AllClinics;
