import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Tooltip, Input } from "antd";
import {
	RotateLeftOutlined,
	RotateRightOutlined,
	SearchOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from "@ant-design/icons";

const FilePreview = ({
	title,
	previewImage,
	onCancel,
	openState,
}: {
	title: string;
	previewImage: string;
	onCancel: () => void;
	openState: boolean;
}) => {
	const [isBW, setIsBW] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const [isMagnified, setisMagnified] = useState(false);
	const [magnifyingDegree, setMagnifiyingDegree] = useState(0);
	const [zoomPercent, setZoomPercent] = useState(1);
	const imageRef = useRef<HTMLImageElement>(null);
	const [rotateAngle, setRotateAngle] = useState(90);

	useEffect(() => {
		if (isMagnified) {
			magnifyFunc(3);
		}
	}, [isMagnified]);

	useEffect(() => {
		if (isZoomed || (rotateAngle != 0 && rotateAngle != 90)) {
			setisMagnified(false);
		}
	}, [isZoomed, rotateAngle]);
	const zoomInFunc = () => {
		if (!isZoomed) setIsZoomed(true);
		setZoomPercent((prev) => prev + 0.5);
		setisMagnified(false);
	};
	const zoomOutFunc = () => {
		setIsZoomed(false);
		setZoomPercent(1);
	};

	// going agains many React priniciples to ge thi workign. will revisit later
	const magnifyFunc = (zoom: number) => {
		let img: HTMLImageElement = document.getElementById("preview-image-id") as HTMLImageElement;
		let glass: HTMLDivElement = document.getElementById("img-magnifier-glass") as HTMLDivElement;

		/* Set background properties for the magnifier glass: */
		glass.style.backgroundImage = "url('" + img.src + "')";
		glass.style.backgroundRepeat = "no-repeat";
		glass.style.backgroundSize = img.width * zoom + "px " + img.height * zoom + "px";
		let bw = 3;
		let w = glass.offsetWidth / 2;
		let h = glass.offsetHeight / 2;

		const moveMagnifier = (e: any) => {
			var pos, x, y;
			/* Prevent any other actions that may occur when moving over the image */
			e.preventDefault();
			/* Get the cursor's x and y positions: */
			pos = getCursorPos(e);
			x = pos.x;
			y = pos.y;
			/* Prevent the magnifier glass from being positioned outside the image: */
			if (x > img.width - w / zoom) {
				x = img.width - w / zoom;
			}
			if (x < w / zoom) {
				x = w / zoom;
			}
			if (y > img.height - h / zoom) {
				y = img.height - h / zoom;
			}
			if (y < h / zoom) {
				y = h / zoom;
			}
			/* Set the position of the magnifier glass: */
			glass.style.left = x - w + "px";
			glass.style.top = y - h + "px";
			/* Display what the magnifier glass "sees": */
			glass.style.backgroundPosition = "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
		};

		const getCursorPos = (e: any) => {
			var a,
				x = 0,
				y = 0;
			e = e || window.event;
			/* Get the x and y positions of the image: */
			a = img.getBoundingClientRect();
			/* Calculate the cursor's x and y coordinates, relative to the image: */
			x = e.pageX - a.left;
			y = e.pageY - a.top;
			/* Consider any page scrolling: */
			x = x - window.pageXOffset;
			y = y - window.pageYOffset;
			return { x: x, y: y };
		};

		/* Execute a function when someone moves the magnifier glass over the image: */
		glass.addEventListener("mousemove", moveMagnifier);
		img.addEventListener("mousemove", moveMagnifier);

		/*and also for touch screens:*/
		glass.addEventListener("touchmove", moveMagnifier);
		img.addEventListener("touchmove", moveMagnifier);
	};

	const rotateAngleFunc = () => {
		if (rotateAngle < 360) {
			setRotateAngle((prev) => prev + 90);
		} else if (rotateAngle > 360) {
			setRotateAngle(0);
		} else {
			setRotateAngle(90);
		}
	};
	return (
		<Modal
			open={openState}
			title={title}
			footer={null}
			onCancel={() => {
				onCancel();
				setIsBW(false);
				setIsZoomed(false);
				setZoomPercent(1);
				setisMagnified(false);
				setMagnifiyingDegree(0);
				setRotateAngle(0);
				imageRef!.current!.style.rotate = `0deg`;
			}}
			width={1000}
			centered>
			<div
				style={{
					position: "relative",
					display: "grid",
					maxHeight: "80vh",
					overflow: "auto",
					cursor: isMagnified ? "none" : "all-scroll",
				}}
				className="custom-scrollbar">
				<div id="img-magnifier-glass" style={{ display: isMagnified ? "block" : "none" }}></div>
				<img
					alt=""
					id="preview-image-id"
					ref={imageRef}
					style={{
						objectFit: "contain",
						WebkitFilter: `grayscale(${isBW ? "100%" : "0%"})`,
						filter: `grayscale(${isBW ? "100%" : "0%"}) scale(${zoomPercent})`,
						width: zoomPercent == 1 ? "100%" : "auto",
						height: zoomPercent == 1 ? "100%" : "auto",
					}}
					src={previewImage}
					className={`preview-img `}
				/>
			</div>
			<div className="d-flex justify-content-center bg-black py-2 gap-2 mt-3">
				<Tooltip title="Zoom In">
					<Button
						type={isZoomed ? "primary" : "default"}
						onClick={(e) => {
							zoomInFunc();
						}}
						icon={<ZoomInOutlined style={{ fontSize: 18 }} />}
					/>
				</Tooltip>
				<Tooltip title="Zoom Out">
					<Button
						onClick={(e) => {
							zoomOutFunc();
						}}
						icon={<ZoomOutOutlined style={{ fontSize: 18 }} />}
					/>
				</Tooltip>
				<Tooltip title="Magnifying Glass">
					<Button
						disabled={isZoomed || (rotateAngle != 0 && rotateAngle != 90) ? true : false}
						type={isMagnified ? "primary" : "default"}
						onClick={(e) => {
							setisMagnified((prev) => !prev);
							isMagnified && setMagnifiyingDegree(3);
							isMagnified && setIsZoomed(false);
						}}
						icon={<SearchOutlined style={{ fontSize: 18 }} />}
					/>
				</Tooltip>

				<Tooltip title="Rotate 90° Left">
					<Button
						onClick={(e) => {
							rotateAngleFunc();
							if (imageRef.current) {
								imageRef.current.style.rotate = `-${rotateAngle}deg`;
							}
						}}
						icon={<RotateLeftOutlined />}
					/>
				</Tooltip>

				<Tooltip title="Rotate 90° Right">
					<Button
						onClick={(e) => {
							rotateAngleFunc();

							if (imageRef.current) {
								imageRef.current.style.rotate = `${rotateAngle}deg`;
							}
							// @ts-ignore
						}}
						icon={<RotateRightOutlined />}
					/>
				</Tooltip>

				<Tooltip title="Black and White">
					<Button
						onClick={(e) => {
							setIsBW((prev) => !prev);
						}}
						type={isBW ? "primary" : "default"}>
						<strong>B/W</strong>
					</Button>
				</Tooltip>
				{/*
					<Button icon={} />
					<Button icon={} />
					<Button icon={} />
					<Button icon={} />
					<Button icon={} /> */}
			</div>
		</Modal>
	);
};

export default FilePreview;
