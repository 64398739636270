import { createAsyncThunk } from "@reduxjs/toolkit";
import { PatientDataType } from "../../../utils";
import { AXIOS_CLIENT } from "../../axiosInstance";
import { Id, toast } from "react-toastify";
import { AxiosError } from "axios";
import { store } from "../../store";

let loader: Id;

export const getPatients = createAsyncThunk("patients/fetchAll", async () => {
	let response = await AXIOS_CLIENT.get("patients/all");
	return response.data;
});

export const createPatient = createAsyncThunk("patients/createNew", async (patientData: PatientDataType) => {
	try {
		loader = toast.loading("Creating the patient...", { containerId: "toast" });

		let response = await AXIOS_CLIENT.post(`patients`, patientData);

		toast.dismiss(loader);
		toast.success("Patient created successfully", { containerId: "toast" });

		return response.data;
	} catch (err: any) {
		let error: AxiosError<any> = err;
		if (!error.response) {
			throw err;
		}
		toast.dismiss(loader);
		toast.error(error.response.data.message as string, {
			containerId: "toast",
		});
	}
});

export const updatePatient = createAsyncThunk("patients/update", async (patientData: PatientDataType) => {
	try {
		loader = toast.loading("Updating the patient...", { containerId: "toast" });

		let response = await AXIOS_CLIENT.put(`patients/${patientData.id}`, patientData);

		toast.dismiss(loader);
		toast.success("Patient updated successfully", { containerId: "toast" });

		return response.data;
	} catch (err: any) {
		let error: AxiosError<any> = err;
		if (!error.response) {
			throw err;
		}
		toast.dismiss(loader);
		toast.error(error.response.data.message as string, {
			containerId: "toast",
		});
	}
});

export const deletePatient = createAsyncThunk("patients/delete", async (Id: number) => {
	let response = await AXIOS_CLIENT.delete(`patients/${Id}`);
	return response.data;
});
