import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { PatientDataType, UserDataType, SMS_URL } from "../../../utils";
import { AXIOS_CLIENT } from "../../axiosInstance";
import { RootState } from "../../store";

export const notificationOrderStarter = {
  channel: "EMAIL",
  delay: 0,
  id: 0,
  kind: "IMMEDIATE",
  message: {
    body: "",
    countryCode: "",
    id: 0,
    recipient: "",
    subject: "",
  },
};

export const sendNewUserEmail = createAsyncThunk(
  "notifications/new_user",
  async (data: UserDataType & { password: string }) => {
    let response = await AXIOS_CLIENT.post("notification/order", {
      ...notificationOrderStarter,
      message: {
        subject: "Registration Successful!",
        recipient: data?.email,
        body: `Hello ${
          data.fullNames
        } \n You are a now a registered ${data.roles[0]
          .split("_")[1]
          .toLowerCase()} on the Alive Botswana Platform \nYour current Login details are \nUsername : ${
          data.username
        } \nPassword : ${
          data.password
        } \nPlease log in at https://mpona.com/change/${
          data.username
        } to change your password `,
      },
    });
    await AXIOS_CLIENT.put(
      `notification/email?messageId=${response.data?.message?.id}`
    );

    return response.data;
  }
);

export const newBookingNotification = createAsyncThunk(
  "notification/new_booking",
  async (data: UserDataType) => {
    let response = await AXIOS_CLIENT.post("notification/order", {
      ...notificationOrderStarter,
      message: {
        subject: "Booking Alert",
        recipient: data?.email,
        body: `Dear Doctor ${data?.fullNames} \nA new booking has been set for you. \nLogin to accept, modify or decline`,
      },
    });
    await AXIOS_CLIENT.put(
      `notification/email?messageId=${response.data?.message?.id}`
    );

    return response.data;
  }
);

export const newPrescriptionNotification = createAsyncThunk(
  "notification/prescription",
  async (data: PatientDataType, { getState }) => {
    let state = getState() as RootState;

    let message = `Dear ${
      data?.firstname
    }\n Your new prescription is ${state.medicals.prescriptions.active?.map(
      (item) => `${item?.title} : ${item?.description}  `
    )}`;

    // send sms
    let response = await axios.get(
      `${SMS_URL}to=${data.mobile}&msg=${message}`
    );
    return response.data;
  }
);

export const newVisitNotification = createAsyncThunk(
  "notification/visit",
  async (data: PatientDataType) => {
    // send email
    let response = await AXIOS_CLIENT.post("notification/order", {
      ...notificationOrderStarter,
      message: {
        subject: "Your Results are ready",
        recipient: data?.email,
        body: `Dear ${data?.firstname} \n Your results have been completed. \nKindly contact your clinic to get the results`,
      },
    });

    let message = `Dear ${data?.firstname} \n Your results have been completed. \nKindly contact your clinic to get the results`;

    await AXIOS_CLIENT.put(
      `notification/email?messageId=${response.data?.message?.id}`
    );

    // send sms
    await axios.get(`${SMS_URL}to=${data.mobile}&msg=${message}`);

    return response.data;
  }
);

export const newPatientFileNotification = createAsyncThunk(
  "notification/file",
  async ({
    patientdata,
    recipient,
    bookingRef,
  }: {
    patientdata: PatientDataType;
    recipient: string;
    bookingRef: string;
  }) => {
    let response = await AXIOS_CLIENT.post("notification/order", {
      ...notificationOrderStarter,
      message: {
        subject: `Patient File is ready`,
        recipient: recipient,
        body: `The file for patient ${patientdata?.idNumber} ( ${patientdata?.firstname} ${patientdata?.lastname} ) has been completed and is attached below)`,
      },
    });

    await AXIOS_CLIENT.put(
      `notification/email?messageId=${response.data?.message?.id}&type=clinic&bookingRef=${bookingRef}`
    );

    return response.data;
  }
);
