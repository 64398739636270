import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Popup = (props: { text: string }) => {
  toast(props.text, { position: "top-center", containerId: "toast" });
  toast.clearWaitingQueue();

  return <div></div>;
};

export default Popup;
