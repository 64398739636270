import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BookingDataType, DataSliceType } from "../../../utils";
import { getBookings, createBooking, updateBooking, deleteBooking, generateBookingRef } from "./thunks";

const initialState: DataSliceType<BookingDataType> = {
	latest: null,
	active: {
		id: 0,
		bookingRef: "",
		clinic: "",
		dateBooked: "",
		timeSlot: "",
		doctor: "",
		attendingTechName: "",
		bloodPressure: "",
		pulseReading: "",
		oxygenSaturation: "",
		temp: "",
		weight: "",
		height: "",
		current_complaints: "",
		status: "",
		randomBloodSugar: "",
		medicalHistory: "",
		reviewOfSystems: "",
		patientNationalID: "",
		patientName: "",
		patientLastName: "",
		physicalExamination: "",
		referralReason: "",
		dateOfLastScreeningTest: "",
		chronicMedConditions: [],
		chronicMedications: [],
		hba1C: "",
		visualAcuity: { leftEye: "", rightEye: "" },
		ipoResult: { leftEye: "", rightEye: "" },
		bookingImages: [],
	},
	all: [],
};

const bookingSlice = createSlice({
	name: "bookings",
	initialState,
	reducers: {
		setLatestBooking: (state, action: PayloadAction<BookingDataType>) => {
			state.latest = action.payload;
		},
		/**
		 * sets the actively edited booking to object payload or initialState
		 */
		setActiveBooking: (state, action: PayloadAction<BookingDataType | undefined>) => {
			state.active = action.payload ? action.payload : initialState.active;
		},
		updateActiveBooking: (
			state,
			action: PayloadAction<{
				name: string;
				value: string | number;
			}>
		) => {
			//  @ts-ignore
			state.active[action.payload.name] = action.payload.value;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(generateBookingRef.fulfilled, (state, action) => {
				if (action.payload) {
					state.active.bookingRef = action.payload;
				}
				return state;
			})
			.addCase(getBookings.fulfilled, (state, action) => {
				if (action.payload) state.all = action.payload;
				return state;
			})
			.addCase(createBooking.fulfilled, (state, action) => {
				if (action.payload) {
					state.latest = action.payload;
					state.active = initialState.active;
					state.all.push(action.payload);
				}
			})
			.addCase(updateBooking.fulfilled, (state, action) => {
				if (action.payload) {
					state.latest = action.payload;
					let index = state.all.findIndex((item) => item.bookingRef === action.payload?.bookingRef);
					state.all[index] = action.payload;
				}
			})
			.addCase(deleteBooking.fulfilled, (state, action) => {
				if (action.payload) {
					state.all = state.all.filter((item) => item.id !== action.payload);
					state.latest = initialState.latest;
				}
			});
	},
});

export const { setLatestBooking, updateActiveBooking, setActiveBooking } = bookingSlice.actions;

export default bookingSlice.reducer;
