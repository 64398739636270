import React from "react";
import { NavLink } from "react-router-dom";
import { toggleSidebar, useAppDispatch } from "../../appStore";

/**
 * @Todo implement redux here
 * @param props
 * @returns
 */

const IconLink = (props: {
  to: string;
  icon: React.ReactNode;
  text: string;
}) => {
  const dispatch = useAppDispatch();
  return (
    <NavLink
      className="row flex-d py-3 px-1 icon-link-group"
      activeClassName="active-icon-group"
      to={props.to}
      onClick={(e) => {
        dispatch(toggleSidebar());
      }}
    >
      <span className="col-2 offset-1 ">{props.icon}</span>{" "}
      <span className="col-8">{props.text}</span>
    </NavLink>
  );
};

export default IconLink;
