import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataSliceType, UserDataType } from "../../../utils";
import {
	createPlatformUser,
	getSinglePlatformUser,
	deletePlatformUser,
	getPlatformUsers,
	updatePlatformUser,
} from "./thunks";

const initialState: DataSliceType<UserDataType> = {
	latest: null,
	active: {
		id: 0,
		username: "",
		email: "",
		status: 0,
		landline: "",
		fullNames: "",
		roles: [],
		mobileNumber: "",
		clinicId: {
			address: "string",
			id: 0,
			name: "string",
		},
	},
	all: [],
};

const Slice = createSlice({
	name: "platformUsers",
	initialState,
	reducers: {
		setLatestPlatformUser: (state, action: PayloadAction<UserDataType>) => {
			state.latest = action.payload;
		},
		updateActivePlatformUser: (
			state,
			action: PayloadAction<{
				name: keyof UserDataType;
				value: string | number;
			}>
		) => {
			//  @ts-ignore
			state.active[action.payload.name] = action.payload.value;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPlatformUsers.fulfilled, (state, action) => {
				if (action.payload) state.all = action.payload.userDataDTOList;
				return state;
			})
			.addCase(getSinglePlatformUser.fulfilled, (state, action) => {
				if (action.payload) state.latest = action.payload;
				return state;
			})
			.addCase(createPlatformUser.fulfilled, (state, action) => {
				if (action.payload) {
					state.latest = action.payload;
					action.payload && state.all.push(action.payload);
				}
			})
			.addCase(updatePlatformUser.fulfilled, (state, action) => {
				if (action.payload) {
					state.latest = action.payload;
					let index = state.all.findIndex((item) => item.id === action.payload.id);
					state.all[index] = action.payload;
				}
			})
			.addCase(deletePlatformUser.fulfilled, (state, action) => {
				if (action.payload) {
					state.all = state.all.filter((item) => item.username !== action.payload);
					state.latest = initialState.latest;
				}
			});
	},
});

export const { setLatestPlatformUser, updateActivePlatformUser } = Slice.actions;

export default Slice.reducer;
