import React from "react";
import { useState } from "react";
import { useAsyncDebounce } from "react-table";
import SearchBar from "../form/SearchBar";
import { Form, Input } from "antd";

const GlobalFilter = ({
	globalFilter,
	setGlobalFilter,
	formtext,
}: {
	globalFilter: any;
	setGlobalFilter: any;
	formtext: string;
}) => {
	const [value, setValue] = useState(globalFilter);

	const handleChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 300);

	const { Search } = Input;
	return (
		<Form.Item style={{ maxWidth: "450px", marginBottom: 0 }}>
			<Search
				enterButton
				placeholder="Enter text to filter table"
				value={value || ""}
				onChange={(e) => {
					setValue(e?.target.value);
					handleChange(e?.target.value);
				}}
			/>
			{formtext ? <p className="small">{formtext}</p> : null}
		</Form.Item>
	);
};
export default GlobalFilter;
