import React, { useEffect, useState } from "react";
import { NavLink, Route } from "react-router-dom";
import {
  authenticate,
  login,
  selectLoggedInUser,
  useAppDispatch,
  useAppSelector,
} from "../appStore";
import { Logo } from "../components";

const Login = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectLoggedInUser);

  useEffect(() => {
    if (user.isAuthenticated)
      window.location.href = window.location.origin + "/main";
  }, [user.isAuthenticated]);

  useEffect(() => {
    if (user.isLoggedIn) dispatch(authenticate());
  }, [user.isLoggedIn]);

  return (
    <div className="App">
      <div className="appAside" />
      <div className="appForm">
        <Logo />
        <h2 className="formTitle mt-3">Sign In</h2>
        <LoginForm />
      </div>
    </div>
  );
};

export const LoginForm = () => {
  const dispatch = useAppDispatch();

  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <div className="">
      <div className="formCenter">
        <form
          className="formFields"
          onSubmit={(e) => {
            e.preventDefault();
            dispatch(login(form));
          }}
        >
          <div className="formField">
            <label className="formFieldLabel" htmlFor="username">
              Please enter your username
            </label>
            <input
              autoComplete="off"
              type="text"
              name="username"
              onChange={handleChange}
              className="formFieldInput"
              placeholder="Enter your username"
              value={form.username}
            />
          </div>

          <div className="formField">
            <label className="formFieldLabel" htmlFor="password">
              Please enter your Password
            </label>
            <input
              autoComplete="off"
              type="password"
              name="password"
              onChange={handleChange}
              className="formFieldInput"
              placeholder="Enter your password"
              value={form.password}
            />
          </div>

          <div className="formField">
            <button className="formFieldButton">Sign In</button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
