import { createSlice } from "@reduxjs/toolkit";
import { getLatestPatientExam } from "./thunks";

const initialState = {
	images: [],
};

const remidioSlice = createSlice({
	name: "camera",
	initialState,
	reducers: {
		clearCameraImages: (state) => {
			state.images = [];
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getLatestPatientExam.fulfilled, (state, action) => {
			state.images = action.payload;
		});
	},
});

export const { clearCameraImages } = remidioSlice.actions;

export default remidioSlice.reducer;
