import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectAllPrescriptions = createSelector(
  [(state: RootState) => state?.medicals?.prescriptions?.all],
  (pres) => pres
);

export const selectLatestPrescription = createSelector(
  [(state: RootState) => state?.medicals?.prescriptions?.latest],
  (pres) => pres
);
export const selectActivePrescription = createSelector(
  [(state: RootState) => state?.medicals?.prescriptions?.active],
  (pres) => pres
);

export const selectPrescriptionByRef = createSelector(
  [selectAllPrescriptions, (state, ref) => ref],
  (pres, ref) => pres?.filter((item) => item?.bookingRef === ref)
);
