import React, { useState, useEffect } from "react";
import { IconLinkGroup as IconLink, Logo, MenuToggle } from "../";
import { NavLink, Link } from "react-router-dom";
import { CaretDownFill } from "react-bootstrap-icons";
import {
	selectLoggedInUser,
	useAppDispatch,
	useAppSelector,
	toggleSidebar as toggleSidebarAction,
	signout,
} from "../../appStore";
import { Dropdown, Menu, Avatar } from "antd";
import { UserOutlined, CaretDownOutlined } from "@ant-design/icons";

const Sidebar = ({ isSuperAdmin }: { isSuperAdmin?: boolean }) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectLoggedInUser);
	let showSidebar = useAppSelector((state) => state.layout.sidebarStatus);
	let isAdmin = user?.roles[0] === "ROLE_ADMIN";
	let isDoctor = user?.roles[0] === "ROLE_DOCTOR";
	let isTechnician = user?.roles[0] === "ROLE_TECHNICIAN";
	let isGrader = user?.roles[0] === "FIRST_GRADER"; // graders

	const toggleSidebar = () => {
		dispatch(toggleSidebarAction());
	};

	return (
		<nav className={`custom-navbar`}>
			<div className="container-full">
				<div className="d-flex">
					<NavLink to={user.isLoggedIn ? "/main" : "/"} style={{ display: "contents" }}>
						<Logo className="sidebar-logo" />
					</NavLink>
				</div>

				<MenuToggle />

				<div className="nav-list" id="main-nav-menu">
					{/* Seperate header for super admins */}
					{isSuperAdmin ? (
						<div className="d-flex flex-column flex-lg-row flex-wrap" style={{ gap: "32px" }}>
							<Dropdown
								overlay={
									<Menu>
										<Menu.Item>
											<NavLink to="/superadmin/dashboard">Create a new User</NavLink>
										</Menu.Item>
										<Menu.Item>
											<NavLink to="/superadmin/dashboard/users/all">All users</NavLink>
										</Menu.Item>
									</Menu>
								}>
								<NavLink to="/superadmin/dashboard" activeClassName="active-icon-group" exact>
									Users
								</NavLink>
							</Dropdown>
							<Dropdown
								overlay={
									<Menu>
										<Menu.Item>
											<NavLink to="/superadmin/dashboard/clinic/createnew">Create a clinic</NavLink>
										</Menu.Item>
										<Menu.Item>
											<NavLink to="/superadmin/dashboard/clinic/all">All clinics</NavLink>
										</Menu.Item>
									</Menu>
								}>
								<NavLink to="/superadmin/dashboard/clinic/all" activeClassName="active-icon-group" exact>
									Clinics
								</NavLink>
							</Dropdown>
						</div>
					) : (
						// regular header for other users
						<div className="d-flex flex-column flex-lg-row flex-wrap" style={{ gap: "32px" }}>
							{(isAdmin || isTechnician || isGrader) && (
								<>
									<NavLink to="/main" exact activeClassName="active-icon-group">
										Statistics
									</NavLink>
								</>
							)}
							{(isAdmin || isTechnician) && (
								<>
									<Dropdown
										overlay={
											<Menu>
												<Menu.Item>
													<NavLink to="/main/patient">Create new patient</NavLink>
												</Menu.Item>
												{/* <Menu.Item>
												<NavLink to="/main/patient/search/">Search Existing Patient</NavLink>
											</Menu.Item> */}
												<Menu.Item>
													<NavLink to="/main/patient/all">View all patients</NavLink>
												</Menu.Item>
											</Menu>
										}>
										<NavLink to="/main/patient" activeClassName="active-icon-group">
											Patient
										</NavLink>
									</Dropdown>

									<Dropdown
										overlay={
											<Menu>
												{isAdmin || isTechnician ? (
													<Menu.Item>
														<NavLink to="/main/booking/combined">Create new booking</NavLink>
													</Menu.Item>
												) : undefined}

												{isAdmin ? (
													<Menu.Item>
														<NavLink to="/main/booking/dr_screening">DR Screening Form</NavLink>
													</Menu.Item>
												) : null}

												{isAdmin || isTechnician ? (
													<>
														<Menu.Item>
															<NavLink to="/main/booking/">Technician Screening Form</NavLink>
														</Menu.Item>

														<Menu.Item>
															<NavLink to="/main/booking/all">View existing bookings</NavLink>
														</Menu.Item>
													</>
												) : undefined}
											</Menu>
										}>
										<NavLink to="/main/booking" activeClassName="active-icon-group">
											Booking
										</NavLink>
									</Dropdown>
								</>
							)}

							{isAdmin || isGrader ? (
								<Dropdown
									overlay={
										<Menu>
											<Menu.Item>
												<NavLink to="/main/visit/">Complete a File</NavLink>
											</Menu.Item>
											{/* <Menu.Item>
													<NavLink to="/main/visit/search_result">Search Visit Records</NavLink>
												</Menu.Item> */}
											<Menu.Item>
												<NavLink to="/main/visit/allvisits">Completed Records</NavLink>
											</Menu.Item>
										</Menu>
									}>
									<NavLink to="/main/visit" activeClassName="active-icon-group">
										Records
									</NavLink>
								</Dropdown>
							) : null}

							{isAdmin && (
								<>
									<Dropdown
										overlay={
											<Menu>
												<Menu.Item>
													<NavLink to="/main/users">Create a new User</NavLink>
												</Menu.Item>
												<Menu.Item>
													<NavLink to="/main/users/all">All users</NavLink>
												</Menu.Item>
											</Menu>
										}>
										<NavLink to="/main/users" activeClassName="active-icon-group">
											Users
										</NavLink>
									</Dropdown>
									{/* <Dropdown
										overlay={
											<Menu>
												<Menu.Item>
													<NavLink to="/main/resources">Resources</NavLink>
												</Menu.Item>
												<Menu.Item>
													<NavLink to="/main/medical">Prescriptions</NavLink>
												</Menu.Item>
											</Menu>
										}>
										<NavLink to="/main/resources" activeClassName="active-icon-group">
											Tools
										</NavLink>
									</Dropdown> */}
									{/* <Dropdown
										overlay={
											<Menu>
												<Menu.Item>
													<NavLink to="/main/settings/profile">Profile Settings</NavLink>
												</Menu.Item>
												<Menu.Item>
													<NavLink to="/main/settings/camera">Camera Settings</NavLink>
												</Menu.Item>
											</Menu>
										}>
										<NavLink to="/main/settings/profile" activeClassName="active-icon-group">
											Settings
										</NavLink>
									</Dropdown> */}
								</>
							)}

							{/* Doctors / Referring CLinicians */}
							{isDoctor && (
								<>
									<NavLink to="/main/booking/" activeClassName="active-icon-group" exact>
										Book a patient
									</NavLink>
									<NavLink to="/main/booking/all" activeClassName="active-icon-group">
										View Booked Patients
									</NavLink>
									<NavLink to="/main/visit/allvisits" activeClassName="active-icon-group">
										View Patient results
									</NavLink>
								</>
							)}
						</div>
					)}

					<UserInfo />
				</div>
			</div>
		</nav>
	);
};

const UserInfo = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectLoggedInUser);
	const [istoggled, setIsToggled] = useState(false);

	return (
		<>
			<div>
				<div
					onClick={() => {
						setIsToggled((prev) => !prev);
					}}
					className="d-flex gap-2 align-items-center">
					<Avatar style={{ backgroundColor: "#87d068" }} icon={<UserOutlined />} />
					<Link to="/main" className="pt-1">
						{user.username}
					</Link>
					<CaretDownOutlined />
				</div>
			</div>
			<ul
				className="mt-5 dropdown-card shadow-class"
				style={istoggled ? { display: "grid", backgroundColor: "black", gap: "10px" } : { display: "none" }}>
				<li className="dropdown-item">{user.email}</li>
				<li className="dropdown-item">
					<Link to="/main">Dashboard</Link>{" "}
				</li>
				<li className="dropdown-item">
					<Link to="/main/notifications">Notifications</Link>
				</li>
				<li className="dropdown-item">
					<Link to="/main/help">Help</Link>
				</li>
				<li className="dropdown-item">
					<button onClick={(e) => dispatch(signout())} className="btn alt-lime">
						Logout
					</button>{" "}
				</li>
			</ul>
		</>
	);
};

export default Sidebar;
