import { createSelector } from "@reduxjs/toolkit";
import { UploadFile } from "antd/es/upload";
import axios from "axios";
import { RootState } from "../../store";

export const selectLatestCameraImages = createSelector(
	[(state: RootState) => state],

	(state) => {
		return state.camera?.images;
	}
);

// let obj = { uid: string;
//     size?: number;
//     name: string;
//     fileName?: string;
//     lastModified?: number;
//     lastModifiedDate?: Date;
//     url?: string;
//     status?: UploadFileStatus;
//     percent?: number;
//     thumbUrl?: string;
//     crossOrigin?: React.ImgHTMLAttributes<HTMLImageElement>['crossOrigin'];
//     originFileObj?: RcFile;
//     response?: T;
//     error?: any;
//     linkProps?: any;
//     type?: string;
//     xhr?: T;
//     preview?: string;}
