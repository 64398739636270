import React, { HTMLProps } from "react";

const Textarea = (
  props: HTMLProps<HTMLTextAreaElement> & { label: string; formtext?: string }
) => {
  return (
    <div className="col-md-6">
      <label className="form-label">{props.label}</label>
      <textarea {...props} className="form-control" />
      <p className="form-text">{props.formtext}</p>
    </div>
  );
};

export default Textarea;
