type obj = Partial<{
	date_created: string;
	date_opened: string;
	dateBooked: string;
}>;

type ArrayOrStr<T extends obj | string> = T extends obj ? T[] : string;

function formatDate<T extends obj>(array: T[]) {
	if (Array.isArray(array)) {
		return array.map((obj) => {
			return {
				...obj,
				date_created: formatStringDate(obj["date_created"] as string),
				date_opened: formatStringDate(obj["date_opened"] as string),
				dateBooked: formatStringDate(obj.dateBooked as string),
			};
		});
	} else if (typeof array === "string") {
		formatStringDate(array);
	} else {
		return new Date().toLocaleString().split(",")[0];
	}
}

export default formatDate;

export const formatStringDate = (d: string) => {
	let date = new Date(d);
	if (Object.prototype.toString.call(date) === "[object Date]") {
		if (!isNaN(date as unknown as number)) {
			return new Date(date).toLocaleString().split(",")[0];
		}
	} else {
		return "";
	}
};
