import { ArrowLeftCircle } from "react-bootstrap-icons";
import { ButtonPropsType } from "../../utils";

const BackButton = (props: ButtonPropsType) => {
  return (
    <button
      className={`btn bg-white ${props.className}`}
      style={{ width: "max-content", height: "max-content" }}
      onClick={props.onClick}
      title={props.title}
    >
      <ArrowLeftCircle style={{ fontSize: "2rem", marginRight: ".5rem" }} />
      {props.text}
      {props.children}
    </button>
  );
};

export default BackButton;
