import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Id, toast } from "react-toastify";
import { BookingDataType } from "../../../utils";
import { AXIOS_CLIENT } from "../../axiosInstance";
import { RootState, store } from "../../store";
import { newBookingNotification } from "../notificationSlice/thunks";

let loader: Id;

export const getBookings = createAsyncThunk("bookings/fetchAll", async () => {
	try {
		let response = await AXIOS_CLIENT.get("bookings/all");
		return response.data;
	} catch (err) {
		toast.error("Error setting up application. please login again", {
			containerId: "toast",
		});
	}
});

export const createBooking = createAsyncThunk(
	"bookings/createNew",
	async (
		{
			patientID,
			bookingData,
		}: {
			patientID: string;
			bookingData: BookingDataType;
		},
		{ getState }
	) => {
		try {
			loader = toast.loading("Creating the booking...", { containerId: "toast" });

			bookingData.dateBooked = new Date().toISOString();
			let response = await AXIOS_CLIENT.post(`bookings/${patientID}`, bookingData);

			toast.dismiss(loader);
			toast.success("booking created successfully", { containerId: "toast" });

			// get doctors details
			let state = getState() as RootState;
			let doctor = state.platformUsers.all.find((user) => user.username === response.data.doctor);

			// send notification to doctor
			doctor && store.dispatch(newBookingNotification(doctor));

			store.dispatch(generateBookingRef());
			// return data
			return response.data;
		} catch (err: any) {
			let error: AxiosError<any> = err;
			if (!err.response) {
				throw err;
			}
			toast.dismiss(loader);
			toast.error(error.response?.data.message, { containerId: "toast" });
		}
	}
);

export const updateBooking = createAsyncThunk("bookings/update", async (bookingData: BookingDataType) => {
	try {
		loader = toast.loading("Updating the booking...", { containerId: "toast" });

		let response = await AXIOS_CLIENT.put(`bookings/${bookingData.id}`, bookingData);

		toast.dismiss(loader);
		toast.success("Booking Updated successfully", { containerId: "toast" });

		response.data.id = bookingData.id;
		return response.data;
	} catch (err: any) {
		let error: AxiosError<any> = err;
		if (!err.response) {
			throw err;
		}
		toast.dismiss(loader);
		toast.error(error.response?.data.message, { containerId: "toast" });
	}
});

export const deleteBooking = createAsyncThunk("bookings/delete", async (bookingId: number) => {
	try {
		let response = await AXIOS_CLIENT.delete(`bookings/${bookingId}`);
		toast.success("Deleted successfully", { containerId: "toast" });
		return bookingId;
	} catch (err: any) {
		let error: AxiosError<any> = err;
		if (!err.response) {
			throw err;
		}
		toast.error(error.response?.data.message, { containerId: "toast" });
	}
});

export const generateBookingRef = createAsyncThunk("bookings/generateRef", async () => {
	let response = await AXIOS_CLIENT.get("bookings/generatebookingid");
	return response.data;
});
