import React, { useState } from "react";
import { BookingDataType, PatientDataType, UserDataType, VisitDataType } from "../../utils";
import Logo from "../pageComponents/Logo";
import FilePreview from "../form/FilePreview";
import _ from "lodash";

type Props = {
	printRef?: React.MutableRefObject<HTMLDivElement | null>;
	bookingData?: BookingDataType;
	patientData?: PatientDataType;
	visitData?: VisitDataType;
	user?: UserDataType;
	imageData?: any[];
	demoOnly?: boolean;
	printOnly?: boolean;
};

const PrintableFile = ({
	printRef,
	bookingData,
	patientData,
	user,
	visitData,
	demoOnly,
	printOnly,
	imageData = [],
}: Props) => {
	const isGrader = user?.roles[0] === "FIRST_GRADER"; // graders

	return (
		<div className="file-container custom-scrollbar" style={{ visibility: printOnly ? "hidden" : "visible" }}>
			<div className={`file`} id="printable_patient_file" ref={printRef}>
				<div className="file-logo">
					<Logo />
				</div>
				<section>
					<h3>Demographics</h3>
					{!isGrader ? (
						<Demographics data={patientData} />
					) : (
						<div className="printable-row">
							<h6>Gender</h6>
							<p>{patientData?.gender}</p>
						</div>
					)}
				</section>
				{!demoOnly ? (
					<>
						<section>
							<h3>Health Data</h3>
							<HealthData data={bookingData} />
						</section>

						<section>
							<h3>Ophthalmologist Report</h3>
							<Opthamologist data={visitData} />
						</section>

						<section>
							<h3>Images</h3>
							<Images props={imageData} />
						</section>
					</>
				) : null}
			</div>
		</div>
	);
};

export default PrintableFile;

const Demographics = ({ data }: { data?: PatientDataType }) => {
	return (
		<div className="printable-row">
			{data?.idNumber && (
				<div>
					<h6>ID Number</h6>
					<p>{data?.idNumber}</p>
				</div>
			)}
			{(data?.firstname || data?.lastname) && (
				<div>
					<h6>Full Name</h6>
					<p>{`${data?.title || ""} ${data?.firstname || ""} ${data?.lastname || ""}`}</p>
				</div>
			)}
			{data?.gender && (
				<div>
					<h6>Gender</h6>
					<p>{data?.gender}</p>
				</div>
			)}
			{data?.dob && (
				<div>
					<h6>Date of Birth</h6>
					<p>{data?.dob}</p>
				</div>
			)}
			{data?.maritalStatus && (
				<div>
					<h6>Marital Status</h6>
					<p>{data?.maritalStatus}</p>
				</div>
			)}
			{data?.occupation && (
				<div>
					<h6>Occupation</h6>
					<p>{data?.occupation}</p>
				</div>
			)}
			{data?.email && (
				<div>
					<h6>Email</h6>
					<p>{data?.email}</p>
				</div>
			)}
			{data?.mobile && (
				<div>
					<h6>Contact No</h6>
					<p>{data?.mobile}</p>
				</div>
			)}

			{data?.address && (
				<div className="full-row">
					<h6>Address</h6>
					<p>{data?.address}</p>
				</div>
			)}
			{/* <div head="" body={data?.} /> */}
		</div>
	);
};

const HealthData = ({ data }: { data?: BookingDataType }) => {
	return (
		<div className="printable-row">
			{data?.chronicMedConditions.length ? (
				<div className="thirds-row">
					<h6>Chronic Medical Conditions</h6>
					{data?.chronicMedConditions.map((condition) => (
						<p className="d-flex justify-content-between mt-2">
							<span>{condition.name}</span>
							<span>{condition.status}</span>
						</p>
					))}
				</div>
			) : null}
			{data?.chronicMedications.length ? (
				<div className="thirds-row">
					<h6>Chronic Medications</h6>
					{data?.chronicMedications.map((condition) => (
						<p className="d-flex justify-content-between mt-2">
							<span>{condition.name}</span>
							<span>{condition.dosage}</span>
						</p>
					))}
				</div>
			) : null}
			{data?.referralReason && (
				<div className="full-row">
					<h6>Reason for referral </h6>
					<p>{data?.referralReason}</p>
				</div>
			)}
			{data?.height && (
				<div>
					<h6>Height </h6>
					<p>{data?.height}</p>
				</div>
			)}
			{data?.weight && (
				<div>
					<h6>Weight</h6>
					<p>{data?.weight}</p>
				</div>
			)}
			{data?.temp && (
				<div>
					<h6>Temperature</h6>
					<p>{data?.temp}</p>
				</div>
			)}
			{data?.bloodPressure && (
				<div>
					<h6>Blood Pressure</h6>
					<p>{data?.bloodPressure}</p>
				</div>
			)}
			{data?.oxygenSaturation && (
				<div>
					<h6>Oxygen Saturation</h6>
					<p>{data?.oxygenSaturation}</p>
				</div>
			)}
			{data?.randomBloodSugar && (
				<div>
					<h6>Random Blood Glucose</h6>
					<p>{data?.randomBloodSugar}</p>
				</div>
			)}

			{data?.current_complaints && (
				<div className="full-row">
					<h6>Current Complaint</h6>
					<p>{data?.current_complaints}</p>
				</div>
			)}
			{(data?.visualAcuity?.leftEye || data?.visualAcuity?.rightEye) && (
				<div className="full-row">
					<h6>Visual Acuity</h6>
					<div className="row flex-wrap gap-2 mt-3">
						<p className="col-4 col-md-2">
							<b>Left: </b>
							{data?.visualAcuity?.leftEye}
						</p>
						<p className="col-4 col-md-2">
							<b>Right: </b>
							{data?.visualAcuity?.rightEye}
						</p>
					</div>
				</div>
			)}
			{(data?.ipoResult?.leftEye || data?.ipoResult?.rightEye) && (
				<div className="full-row">
					<h6>IOP Results</h6>
					<div className="row flex-wrap gap-2 mt-3">
						<p className="col-4 col-md-2">
							<b>Left: </b>
							{data?.ipoResult?.leftEye}
						</p>
						<p className="col-4 col-md-2">
							<b>Right: </b>
							{data?.ipoResult?.rightEye}
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

const Opthamologist = ({ data }: { data?: VisitDataType }) => {
	return (
		<div className="printable-row">
			{data?.diagnosis && (
				<div className="full-row">
					<h6>Diagnosis</h6>
					<p>{data?.diagnosis}</p>
				</div>
			)}
			{data?.doctorRecommendation && (
				<div className="full-row">
					<h6>Recommendation</h6>
					<p>{data?.doctorRecommendation}</p>
				</div>
			)}
		</div>
	);
};

const Images = ({ props }: { props: any[] }) => {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");

	const handleCancel = () => setPreviewOpen(false);

	return (
		<div className="d-flex gap-4 flex-wrap">
			{props.map((i) => {
				let src = i.data ? URL.createObjectURL(i.data) : i.item.s3URL;
				return (
					<div>
						<img
							src={src}
							alt=""
							key={i.item.filename}
							onClick={() => {
								setPreviewImage(src);
								setPreviewTitle(i.item.title || _.truncate(i.item.s3URL, { length: 35 }));
								// setPreviewTitle(i.item.name || i.item.filename.includes("LEFT") ? "Left" : "Right" || "");
								setPreviewOpen(true);
							}}
							style={{
								width: "300px",
								height: "300px",
								maxHeight: "90%",
								maxWidth: "90vw",
							}}
						/>
						<div className="d-flex justify-content-between neutral-bg p-3">
							<h6 title={i?.item?.s3URL}>
								{i.item.s3URL.includes("LEFT")
									? "Left Eye"
									: i.item.s3URL.includes("RIGHT")
									? "Right Eye"
									: i.item.title || _.truncate(i.item.s3URL, { length: 35 })}
							</h6>
						</div>
					</div>
				);
			})}

			<FilePreview openState={previewOpen} title={previewTitle} onCancel={handleCancel} previewImage={previewImage} />
		</div>
	);
};
