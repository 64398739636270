import React, {useState} from "react";
import {FileTextOutlined, PicLeftOutlined, PrinterFilled, MailFilled} from "@ant-design/icons";
import {Button, Row, Space, Modal, Form, Input, Col} from "antd";
import {useReactToPrint} from "react-to-print";
import {
    deletePatient,
    newPatientFileNotification,
    useAppDispatch,
    useAppSelector,
    selectLoggedInUser,
    deleteBooking,
    getPatients,
} from "../../appStore";
import {BookingDataType, PatientDataType} from "../../utils";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import axios from 'axios';
import {Box, LinearProgress} from "@mui/material";

type Props = {
    printRef?: React.MutableRefObject<HTMLDivElement | null>;
    bookingRef?: string;
    patientData?: PatientDataType;
    bookingData?: BookingDataType;
    setCollapsible?: React.Dispatch<React.SetStateAction<boolean>>;
    isCollapsible?: boolean;
    copyRef?: boolean;
};

const PatientFileActionsHeader = ({
                                      printRef,
                                      bookingRef,
                                      patientData,
                                      isCollapsible,
                                      setCollapsible,
                                      bookingData,
                                      copyRef,
                                  }: Props) => {
    const [emailModal, setEmailModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [bookingDeleteModal, setBookingDeleteModal] = useState(false);
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState("");
    const user = useAppSelector(selectLoggedInUser);
    const isGrader = user?.roles[0] === "FIRST_GRADER"; // graders


    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);

    const aiReport = async () => {
        setLoading(true);
        const response = await axios.get(`https://mponafunc.azurewebsites.net/api/aireport?bookingref=${bookingRef}`)
        const url = response.data
        window.open(url, '_blank', 'noopener,noreferrer')
        setLoading(false)
    };

    return (
        <div className="mb-4" style={{width: 816, maxWidth: "95vw"}}>
            <Row justify="space-between" wrap align="middle" gutter={12} style={{rowGap: 12}}>
                <Col>
                    <Space wrap size={12}>
                        <h3>Patient File</h3>
                        {setCollapsible ? (
                            <>
                                <Button
                                    type={isCollapsible ? "default" : "primary"}
                                    icon={<FileTextOutlined/>}
                                    onClick={(e) => {
                                        setCollapsible(false);
                                    }}></Button>
                                <Button
                                    type={!isCollapsible ? "default" : "primary"}
                                    icon={<PicLeftOutlined/>}
                                    onClick={(e) => {
                                        setCollapsible(true);
                                    }}></Button>
                            </>
                        ) : null}
                    </Space>
                </Col>
                <Col>
                    <Space wrap size={12}>
                        <Box>
                        <Button type="primary" icon={<PrinterFilled/>} onClick={aiReport} disabled={loading}>
                            AI Report
                        </Button>
                        {loading && (
                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                <LinearProgress />
                            </Box>
                        )}
                        </Box>
                        <Button icon={<MailFilled/>} onClick={() => setEmailModal((prev) => !prev)}>
                            Email
                        </Button>
                        {!isGrader ? (
                            copyRef && bookingRef ? (
                                <>
                                    <Button
                                        onClick={(e) => {
                                            navigator.clipboard.writeText(bookingRef);
                                            toast("Copied", {containerId: "toast", autoClose: 300});
                                        }}>
                                        Copy Booking Ref
                                    </Button>
                                    <Link to={`/main/booking/create/${bookingRef}`}>
                                        <Button>Edit Booking</Button>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to={`/main/booking/combined/${patientData?.idNumber}`}>
                                        <Button>Create Booking</Button>
                                    </Link>
                                    <Link to={`/main/patient/update_form/${patientData?.id}`}>
                                        <Button>Edit Patient</Button>
                                    </Link>
                                    <Button danger onClick={() => setDeleteModal((prev) => !prev)}>
                                        Delete Patient
                                    </Button>
                                </>
                            )
                        ) : null}
                    </Space>
                </Col>
            </Row>

            <Modal
                open={emailModal}
                title="Custom Email"
                onCancel={() => setEmailModal(false)}
                onOk={() => {
                    setEmailModal(false);
                    if (patientData && bookingRef) {
                        dispatch(
                            newPatientFileNotification({
                                patientdata: patientData,
                                bookingRef,
                                recipient: email,
                            })
                        );
                    }
                }}>
                <Form layout="vertical">
                    <Form.Item label="Enter email to send file" rules={[{type: "email"}]}>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                open={deleteModal}
                title="Delete Confirmation"
                onCancel={() => setDeleteModal(false)}
                okText="Yes, Delete"
                okButtonProps={{danger: true}}
                onOk={() => {
                    setDeleteModal(false);
                    if (patientData?.id) {
                        dispatch(deletePatient(patientData.id));
                        dispatch(getPatients());
                    }
                }}>
                <h6 className="text-danger">Are you sure you want to Delete this patient?</h6>
            </Modal>
            <Modal
                open={bookingDeleteModal}
                title="Delete Confirmation"
                onCancel={() => setBookingDeleteModal(false)}
                okText="Yes, Delete"
                okButtonProps={{danger: true}}
                onOk={() => {
                    setBookingDeleteModal(false);
                    if (bookingData?.id) {
                        dispatch(deleteBooking(bookingData?.id));
                    }
                }}>
                <h6 className="text-danger">Are you sure you want to Delete this booking?</h6>
            </Modal>
        </div>
    );
};

export default PatientFileActionsHeader;
