import React, { useState, useEffect } from "react";
import { createPlatformUser, useAppDispatch, selectAllClinics, getAllClinics, useAppSelector } from "../../appStore";
import { UserDataType, UserRole, option } from "../../utils";
import { SelectSearch } from "../../components";
import { ActionMeta, SingleValue } from "react-select";

const initForm: Omit<UserDataType, "id" | "status"> & { password: string } = {
	username: "",
	password: "",
	email: "",
	roles: ["ROLE_PATIENT"],
	landline: "",
	fullNames: "",
	mobileNumber: "",
	clinicId: {
		address: "string",
		id: 0,
		name: "string",
	},
};

const AddUser = () => {
	const dispatch = useAppDispatch();
	const clinics = useAppSelector(selectAllClinics);
	const [form, setForm] = useState(initForm);

	useEffect(() => {
		dispatch(getAllClinics());
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
	};

	const handleSelect = (option: SingleValue<option>, action: ActionMeta<option>) => {
		setForm({ ...form, [action?.name as string]: option?.value });
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		dispatch(createPlatformUser(form));
	};

	return (
		<>
			<h3>Super Admin : Create new user</h3>
			<form onSubmit={handleSubmit} className="full-form mt-4">
				<div className="form-row">
					<div>
						<label className="form-label">Full Name</label>
						<input
							type="text"
							name="fullNames"
							onChange={handleChange}
							value={form.fullNames}
							placeholder="John Doe Mann"
							className="form-control"
							required
						/>
					</div>
					<div>
						<label htmlFor="username" className="form-label">
							Username
						</label>
						<input
							type="text"
							name="username"
							onChange={handleChange}
							value={form.username}
							placeholder="JohnDoe123"
							className="form-control"
							required
						/>
					</div>
				</div>
				<div className="form-row">
					<div>
						<label htmlFor="email" className="form-label">
							Email
						</label>
						<input
							type="email"
							name="email"
							onChange={handleChange}
							value={form.email}
							placeholder="user@email.com"
							className="form-control"
							required
						/>
					</div>
					<div>
						<label className="form-label">Phone Number</label>
						<input
							type="tel"
							name="mobileNumber"
							onChange={handleChange}
							value={form.mobileNumber}
							placeholder="003 003 003"
							className="form-control"
							required
						/>
					</div>
				</div>
				<div className="form-row">
					<div>
						<label className="form-label">Landline</label>
						<input
							type="tel"
							name="landline"
							onChange={handleChange}
							value={form.landline}
							placeholder="003 003 003"
							className="form-control"
						/>
					</div>
					<div>
						<label htmlFor="password" className="form-label">
							Password
						</label>
						<input
							type="password"
							name="password"
							onChange={handleChange}
							value={form.password}
							placeholder="*******"
							className="form-control"
							required
						/>
					</div>
				</div>
				<div className="form-row">
					<div>
						<label className="form-label">Select User Role</label>
						<select
							name="roles"
							value={form.roles[0]}
							onChange={(e) => {
								setForm((prev) => ({
									...prev,
									roles: [e.target.value as UserRole],
								}));
							}}
							className="form-control"
							required>
							{/* <option value="ROLE_PATIENT">Patient</option> */}
							<option value="ROLE_CLIENT">Client</option>
							<option value="ROLE_ADMIN">Admin</option>
							<option value="ROLE_DOCTOR">Doctor</option>
							<option value="ROLE_TECHNICIAN">Technician</option>
						</select>
					</div>
					<SelectSearch
						label="Choose an available Clinic"
						name="clinic"
						onChange={handleSelect}
						placeholder="Select a Clinic"
						options={clinics}
						keys={{ value: "name" }}
					/>
				</div>
				<div>
					<button className="btn btn-primary mt-1">Add User</button>
				</div>
			</form>
		</>
	);
};
export default AddUser;
