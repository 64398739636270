import React from "react";
import { ButtonPropsType } from "../../utils";

type color = "success" | "danger" | "primary" | "light" | "secondary";

const Button = (props: ButtonPropsType & { color?: color }) => {
	return (
		<button
			className={`btn ${props.color === "secondary" ? "" : "bg-" + props.color} ${props.className}`}
			style={{
				width: "max-content",
				height: "max-content",
				color: props.color !== "light" && props.color !== "secondary" ? "white" : "",
				background: "#f3ede7",
				padding: "4px 15px",
			}}
			onClick={props.onClick}
			type={props.type}
			title={props.title}>
			{props.children}
			{props.text}
		</button>
	);
};

export default Button;
