// @ts-nocheck

import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination, useSortBy } from "react-table";
import GlobalFilter from "./GlobalFilter";
import _ from "lodash";
import SortAsc from "../../assets/images/icons/sorting-asc.svg";
import SortDesc from "../../assets/images/icons/sorting-desc.svg";
import SortNeutral from "../../assets/images/icons/sorting.svg";
import { EllipsisOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

const AsyncTable = ({
	propData,
	formtext = "",
	linked = false,
	initialState = {},
	styled = "basic",
	disableSortList = [""],
	disableLinkList = [""],
	style = {},
}) => {
	const history = useHistory();

	const finalData = useMemo(() => [...propData], [propData]);

	const columns = useMemo(
		() =>
			propData[0]
				? Object.keys(propData[0]).map((key) => {
						let disableSortBy = disableSortList.includes(key);
						return {
							Header: _.startCase(key),
							accessor: key,
							disableSortBy,
							render: (value) => value,
						};
				  })
				: [],

		[]
	);

	const table = useTable({ columns, data: finalData, initialState }, useGlobalFilter, useSortBy, usePagination);

	const {
		getTableBodyProps,
		headerGroups,
		page,
		getTableProps,
		prepareRow,
		setGlobalFilter,
		state,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = table;

	return (
		<>
			<GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} formtext={formtext} />
			<div
				className={`row table-container custom-scrollbar mx-md-1 table-font ${
					styled == "green_white" && "green-white"
				}`}
				style={style}>
				<table
					{...getTableProps()}
					className={
						styled == "green_white"
							? "table table-responsive table-borderless mt-3"
							: "table table-hover table-light table-bordered table-responsive mt-3"
					}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()} className={styled == "green_white" ? "" : "table-secondary"}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())} className="col">
										<div
											className={`d-flex justify-content-between align-items-center ${
												styled == "green_white" ? "text-primary" : ""
											}`}>
											<span>{column.render("Header")}</span>
											<span>
												{" "}
												{column.canSort &&
													(column.isSorted ? (
														column.isSortedDesc ? (
															<img src={SortDesc} alt="" />
														) : (
															<img src={SortAsc} alt="" />
														)
													) : (
														<img src={SortNeutral} alt="" />
													))}
											</span>
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, i) => {
							prepareRow(row);

							return (
								<tr {...row.getRowProps()} className={linked ? "linked" : ""}>
									{row.cells.map((cell) => {
										return (
											<td
												{...cell.getCellProps()}
												onClick={(e) => {
													let allowedLink = disableLinkList.findIndex((item) => {
														return item.toUpperCase() == cell.column.Header.toUpperCase();
													});
													if (allowedLink == -1 && linked) {
														history.push(row.original?.href);
													}
												}}>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<div className="custom-pagination table-font">
				<span>
					Total {pageOptions.length} item{pageOptions.length > 1 ? "s" : ""}
				</span>
				<div className="page-link-group">
					<Button
						type="text"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
						icon={<LeftOutlined />}></Button>
					{pageCount < 6 &&
						Array.from(Array(pageCount).keys()).map((x, i) => (
							<Button
								key={i}
								type="text"
								className={`pagination-link ${pageIndex === i ? "active" : ""}`}
								onClick={() => gotoPage(i)}>
								{i + 1}
							</Button>
						))}
					{pageCount >= 6 &&
						pageIndex < 4 &&
						Array.from(Array(4).keys()).map((x, i) => (
							<Button
								type="text"
								key={i}
								className={`pagination-link ${pageIndex === i ? "active" : ""}`}
								onClick={() => gotoPage(i)}>
								{i + 1}
							</Button>
						))}
					{pageCount >= 6 && pageIndex < 4 && <EllipsisOutlined />}
					{pageCount >= 6 && pageIndex >= 4 && pageIndex <= pageCount - 3 && <EllipsisOutlined />}
					{pageCount >= 6 && pageIndex >= 4 && pageIndex <= pageCount - 3 && (
						<>
							<Button type="text" className="pagination-link" onClick={() => gotoPage(pageIndex - 1)} key={i}>
								{pageIndex}
							</Button>

							<Button type="text" className="pagination-link active" onClick={() => gotoPage(pageIndex)} key={i}>
								{pageIndex + 1}
							</Button>

							<Button type="text" className="pagination-link" onClick={() => gotoPage(pageIndex + 1)} key={i}>
								{pageIndex + 2}
							</Button>
						</>
					)}
					{pageCount >= 6 && pageIndex >= 4 && pageIndex <= pageCount - 3 && <EllipsisOutlined />}
					{pageCount >= 6 && pageIndex > pageCount - 3 && <EllipsisOutlined />}
					{pageCount >= 6 &&
						pageIndex > pageCount - 3 &&
						Array.from(Array(4).keys()).map((x, i) => (
							<Button
								type="text"
								key={i}
								className={`pagination-link ${pageIndex === pageCount - 4 + i ? "active" : ""}`}
								onClick={() => gotoPage(pageCount - 4 + i)}>
								{pageCount - 3 + i}
							</Button>
						))}
					<Button type="text" onClick={() => nextPage()} disabled={!canNextPage} icon={<RightOutlined />}></Button>{" "}
				</div>
				<select
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}>
					{[10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
				<span>
					Go to{" "}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(page);
						}}
						style={{ width: "100px" }}
					/>
				</span>{" "}
			</div>
		</>
	);
};

export default AsyncTable;

// import React, { useMemo, useState } from "react";
// import { useHistory } from "react-router-dom";
// import {
//   useTable,
//   useGlobalFilter,
//   usePagination,
//   UseTableInstanceProps,
// } from "react-table";
// import GlobalFilter from "./GlobalFilter";
// import _ from "lodash";

// const AsyncTable = ({
//   propData,
//   formtext = "",
//   linked = false,
//   initialState = {},
// }: {
//   propData: any[];
//   formtext?: string;
//   linked?: boolean;
//   initialState?: { [index: string]: string };
// }) => {
//   const history = useHistory();
//   const finalData = useMemo(() => [...propData], [propData]);

//   const columns = useMemo(
//     () =>
//       propData[0]
//         ? Object.keys(propData[0]).map((key) => {
//             return { Header: _.startCase(key), accessor: key };
//           })
//         : [],

//     []
//   );

//   const table: UseTableInstanceProps<object> = useTable(
//     { columns, data: finalData, initialState },
//     useGlobalFilter,
//     usePagination
//   );

//   const {
//     getTableBodyProps,
//     headerGroups,
//     page,
//     getTableProps,
//     prepareRow,
//     setGlobalFilter,
//     state,
//     canPreviousPage,
//     canNextPage,
//     pageOptions,
//     pageCount,
//     gotoPage,
//     nextPage,
//     previousPage,
//     setPageSize,
//     state: { pageIndex, pageSize },
//   } = table;

//   return (
//     <>
//       <GlobalFilter
//         globalFilter={state.globalFilter}
//         setGlobalFilter={setGlobalFilter}
//         formtext={formtext}
//       />
//       <div className="row table-container">
//         <table
//           {...getTableProps()}
//           className="table table-striped table-hover table-bordered table-responsive mt-3"
//         >
//           <thead>
//             {headerGroups.map((headerGroup) => (
//               <tr {...headerGroup.getHeaderGroupProps()}>
//                 {headerGroup.headers.map((column) => (
//                   <th {...column.getHeaderProps()} className="col">
//                     {column.render("Header")}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody {...getTableBodyProps()}>
//             {page.map((row, i) => {
//               prepareRow(row);

//               return (
//                 <tr
//                   {...row.getRowProps()}
//                   className={linked ? "linked" : ""}
//                   onClick={(e) => {
//                     linked && history.push(row.original?.href);
//                   }}
//                 >
//                   {row.cells.map((cell) => {
//                     return (
//                       <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
//                     );
//                   })}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </div>

//       <div className="pagination">
//         <div className="button-row">
//           <Button type="text" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
//             {"<<"}
//           </Button>{" "}
//           <Button type="text" onClick={() => previousPage()} disabled={!canPreviousPage}>
//             {"<"}
//           </Button>{" "}
//           <Button type="text" onClick={() => nextPage()} disabled={!canNextPage}>
//             {">"}
//           </Button>{" "}
//           <Button type="text"
//             onClick={() => gotoPage(pageCount - 1)}
//             disabled={!canNextPage}
//           >
//             {">>"}
//           </Button>{" "}
//         </div>
//         <span>
//           Page{" "}
//           <strong>
//             {pageIndex + 1} of {pageOptions.length}
//           </strong>{" "}
//         </span>
//         <span>
//           | Go to page:{" "}
//           <input
//             type="number"
//             defaultValue={pageIndex + 1}
//             onChange={(e) => {
//               const page = e.target.value ? Number(e.target.value) - 1 : 0;
//               gotoPage(page);
//             }}
//             style={{ width: "100px" }}
//           />
//         </span>{" "}
//         <select
//           value={pageSize}
//           onChange={(e) => {
//             setPageSize(Number(e.target.value));
//           }}
//         >
//           {[10, 20, 30, 40, 50].map((pageSize) => (
//             <option key={pageSize} value={pageSize}>
//               Show {pageSize}
//             </option>
//           ))}
//         </select>
//       </div>
//     </>
//   );
// };

// export default AsyncTable;
