import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createBooking, updateBooking } from "../bookingSlice/thunks";
import { createPatient, updatePatient } from "../patientSlice/thunks";
import { getBookingImages } from "./thunks";
import { baseURL } from "../../axiosInstance";

type init = {
	uploadFile: boolean;
	uploadUrl: string;
	currentImages: any[];
	uploadID: number;
};

const initialState: init = {
	uploadFile: false,
	uploadUrl: "",
	uploadID: 0,
	currentImages: [],
};

const fileSlice = createSlice({
	name: "file",
	initialState,
	reducers: {
		setUploadFile: (state, action: PayloadAction<boolean | undefined>) => {
			state.uploadFile = action?.payload || false;
		},
		unsetUploadUrl: (state) => {
			state.uploadUrl = "";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBookingImages.fulfilled, (state, action) => {
				state.currentImages = action.payload ? action.payload : [];
				return state;
			})
			.addCase(createBooking.fulfilled, (state, action) => {
				state.uploadFile = true;
				state.uploadID = action.payload?.id;
				state.uploadUrl = `${baseURL}bookings/updateBookingImages/${action.payload?.id}`;
				return state;
			})
			.addCase(createPatient.fulfilled, (state, action) => {
				state.uploadUrl = `${baseURL}documents/upload/multiple/files/${action.payload?.id}/for/PATIENT`;
				state.uploadFile = true;
				return state;
			})
			.addCase(updateBooking.fulfilled, (state, action) => {
				state.uploadFile = true;
				state.uploadID = action.payload?.id;
				state.uploadUrl = `${baseURL}bookings/updateBookingImages/`;
				return state;
			})
			.addCase(updatePatient.fulfilled, (state, action) => {
				state.uploadUrl = `${baseURL}documents/upload/multiple/files/${action.payload?.id}/for/PATIENT`;
				state.uploadFile = true;
				return state;
			});
	},
});

export const { setUploadFile, unsetUploadUrl } = fileSlice.actions;

export default fileSlice.reducer;
