import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";

type Props = {
	heading?: string;
	text?: string;
	icon?: React.ReactNode;
	color?: string;
	to?: string;
};

const InfoCard = ({ heading, text, icon, color, to = "/" }: Props) => {
	return (
		<Link to={to}>
			<div
				style={{
					padding: "20px",
					borderRadius: "2px",
					backgroundColor: "white",
					border: "var(--light-border)",
					boxShadow: "var(--box-shadow-1)",
					gap: "10px",
				}}>
				<div
					style={{
						display: "flex",
						gap: "10px",
						alignItems: "center",
					}}>
					<div
						className={`${color}`}
						style={{
							width: "32px",
							height: "32px",
							fontSize: "18px",
							borderRadius: "50%",
							display: "inline-flex",
							alignItems: "center",
							justifyContent: "center",
						}}>
						{icon}
					</div>
					<p style={{ fontSize: "14px", color: "var(--light-text)" }}>{text}</p>
				</div>
				<h2 className="bold" style={{ fontSize: "28px" }}>
					{heading}
				</h2>
			</div>
		</Link>
	);
};

export default InfoCard;
