import { BookingDataType, PatientDataType, VisitDataType } from "../types";

const filterVisits = (
  visitData: VisitDataType[],
  patientData: PatientDataType[],
  rawquery: string,
  bookingData: BookingDataType[] = []
) => {
  const query = rawquery.trim();

  // return bookingData
  //   .filter((data) => {
  //     return data.patientIdNumber === query;
  //   })
  //   .map((a) => ({
  //     ...a,
  //     ...patientData.find((b) => b.idNumber == query),
  //   }));

  return visitData
    .filter((data) => {
      return (
        data.patientInternalId == query ||
        data.patientIdNumber === query ||
        data.bookingRefNumber == query
      );
    })
    .map((a) => ({
      ...a,
      ...patientData.find(
        (b) =>
          b.idNumber == query ||
          b.idNumber == a.patientInternalId ||
          b.idNumber == a.patientIdNumber
      ),
      ...bookingData.find((b) => b.bookingRef == a.bookingRefNumber),
    }));
};

export default filterVisits;
