import { createAsyncThunk } from "@reduxjs/toolkit";
import { BookingDataType, VisitDataType } from "../../../utils";
import { AXIOS_CLIENT } from "../../axiosInstance";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { store } from "../../store";
import {
  newPatientFileNotification,
  newVisitNotification,
} from "../notificationSlice/thunks";

export const getVisits = createAsyncThunk("visits/fetchAll", async () => {
  let response = await AXIOS_CLIENT.get("visits/all");
  return response.data;
});

export const createVisit = createAsyncThunk(
  "visits/createNew",
  async ({
    bookingData,
    visitData,
  }: {
    bookingData: BookingDataType;
    visitData: VisitDataType;
  }) => {
    try {
      let response = await AXIOS_CLIENT.post(
        `visits/${bookingData.id}`,
        visitData
      );
      toast.success(
        "This visit has been successfully completed. Hold on while we prepare the result",
        { containerId: "toast" }
      );
      setTimeout(() => {
        window.location.href =
          window.location.origin +
          `/main/patient/search/${bookingData?.patientNationalID}&${bookingData?.bookingRef}`;
      }, 3000);

      let patient = store
        .getState()
        .patients?.all?.find(
          (item) => item?.idNumber == bookingData.patientNationalID
        );

      let clinic = store
        .getState()
        .resources.clinics.find((clinic) => clinic.name == bookingData.clinic);

      patient && store.dispatch(newVisitNotification(patient));
      patient &&
        clinic &&
        store.dispatch(
          newPatientFileNotification({
            patientdata: patient,
            bookingRef: bookingData.bookingRef,
            recipient: clinic?.email,
          })
        );

      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!error.response) {
        throw err;
      }
      toast.error(error.response.data.message as string, {
        containerId: "toast",
      });
    }
  }
);

export const updateVisit = createAsyncThunk(
  "visits/update",
  async ({ Id, visitData }: { Id: number; visitData: VisitDataType }) => {
    let response = await AXIOS_CLIENT.put(`visits/${Id}`, visitData);
    return { ...response.data, id: Id };
  }
);

export const deleteVisit = createAsyncThunk(
  "visits/delete",
  async (Id: number) => {
    let response = await AXIOS_CLIENT.delete(`visits/${Id}`);
    return Id;
  }
);
