import {
	selectLoggedInUser,
	useAppDispatch,
	useAppSelector,
	selectFileUploadStatus,
	selectFileUploadUrl,
	selectFileUploadID,
	setUploadFile,
	unsetUploadUrl,
	getLatestPatientExam,
	selectLatestCameraImages,
	clearCameraImages,
} from "../../appStore";
import _ from "lodash";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import React, { useState, useEffect } from "react";
import { IMAGE_PREVIEW_URL } from "../../utils";
import { AXIOS_CLIENT, baseURL } from "../../appStore/axiosInstance";
import FilePreview from "./FilePreview";

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});

const App = ({ camera, patientID, IdNumber }: { camera?: boolean; patientID?: string; IdNumber?: string }) => {
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [cameraImages, setCameraImages] = useState<any[]>([]);
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const token = useAppSelector(selectLoggedInUser).token;
	const shouldUpload = useAppSelector(selectFileUploadStatus);
	const url = useAppSelector(selectFileUploadUrl);
	const remidioImages = useAppSelector(selectLatestCameraImages);
	const dispatch = useAppDispatch();
	const uploadId = useAppSelector(selectFileUploadID);

	useEffect(() => {
		dispatch(setUploadFile(false));
		dispatch(unsetUploadUrl());
	}, []);

	useEffect(() => {
		shouldUpload &&
			upload().finally(() => {
				dispatch(setUploadFile(false));
				dispatch(unsetUploadUrl());
			});
	}, [shouldUpload]);

	useEffect(() => {
		let arr: any[] = [];

		if (remidioImages.length) {
			patientID &&
				remidioImages?.forEach(async (file: any) => {
					// let data = await AXIOS_CLIENT.get(`${IMAGE_PREVIEW_URL}${file?.doc_key}`);
					// let originFileObj = new File([await data.data], file?.filename);
					arr.push({
						id: `${file?.id}` || file.uid,
						name: `${file?.laterality}`,
						fileName: `${file?.laterality} ${patientID}`,
						url: `${file?.path}`,
						preview: `${file?.thumbnailPath}`,
						status: "done",
						thumbUrl: `${file?.thumbnailPath}`,
						// originFileObj,
					});
				});
		}

		setCameraImages(arr);
	}, [remidioImages]);

	useEffect(() => {
		if (patientID && camera && cameraImages.length) {
			setFileList((prev) => {
				let newImages = cameraImages.filter((file) => {
					let index = prev.findIndex((finder) => (finder.url = file.url));

					return index == -1 ? true : false;
				});
				return [...prev, ...newImages];
			});
		}
	}, [cameraImages]);

	const upload = async () => {
		let formData = new FormData();
		let idArr: string[] = [];

		fileList.forEach((file, index) => {
			file.status = "uploading";
			if (file.originFileObj) {
				formData.append("images", file.originFileObj);
			} else {
				// @ts-ignore
				idArr.push(file?.id);
			}
		});

		let paramString = idArr.length ? "?" + idArr.map((id) => `remidioImgIDs=${id}`).join("&") : "";

		fetch(`${baseURL}bookings/updateBookingImages/${uploadId}`, {
			method: "PUT",
			body: formData,
			headers: {
				"Access-Control-Max-Age": "8000",
				Authorization: `Bearer ${token}`,
				accept: "*/*",
			},
		})
			.then((res) => {
				setFileList((prev) => {
					return prev.map((file) => ({ ...file, status: "done" }));
				});
			})
			.catch((err) => {
				console.log(err);
				setFileList((prev) => {
					return prev.map((file) => ({ ...file, status: "error" }));
				});
			});

		fetch(`${baseURL}bookings/updateBookingImagesRemidio/${uploadId.toString()}&${IdNumber}`, {
			method: "PUT",
			body: JSON.stringify(idArr),

			headers: {
				"Access-Control-Max-Age": "8000",
				Authorization: `Bearer ${token}`,
				accept: "*/*",
				"content-type": "application/json",
			},
		})
			.then((res) => {
				setFileList((prev) => {
					return prev.map((file) => ({ ...file, status: "done" }));
				});
			})
			.catch((err) => {
				console.log(err);
				setFileList((prev) => {
					return prev.map((file) => ({ ...file, status: "error" }));
				});
			});
	};

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.preview as string);
		setPreviewOpen(true);
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
	};

	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
		newFileList.forEach((file) => (file.status = "done"));
		setFileList(newFileList);
	};

	return (
		<div>
			<Upload
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				beforeUpload={() => {
					return false;
				}}>
				{fileList.length >= 8 ? null : (
					<div>
						<PlusOutlined />
						<div style={{ marginTop: 8 }}>Upload</div>
					</div>
				)}
			</Upload>
			<FilePreview openState={previewOpen} title={previewTitle} onCancel={handleCancel} previewImage={previewImage} />

			{camera && IdNumber && <Button onClick={() => dispatch(getLatestPatientExam(IdNumber))}>Get from Camera</Button>}
		</div>
	);
};

export default App;

const uploadFile = (file: File, token: string, url: string) => {
	if (url) {
		let onProgress: any;

		return new Promise((res, rej) => {
			const xhr = new XMLHttpRequest();

			xhr.open("POST", url);

			xhr.withCredentials = true;

			xhr.setRequestHeader("Access-Control-Max-Age", "9000");
			xhr.setRequestHeader("Authorization", `Bearer ${token}`);
			xhr.setRequestHeader("accept", "*/*");

			xhr.onload = () => {
				const response = JSON.parse(xhr.responseText);
				res(response);
			};

			xhr.onerror = (event) => rej(event);

			const formData = new FormData();
			formData.append("images", file);
			xhr.send(formData);
		});
	}
};

// import {
// 	selectLoggedInUser,
// 	useAppDispatch,
// 	useAppSelector,
// 	selectFileUploadStatus,
// 	selectFileUploadUrl,
// 	setUploadFile,
// 	unsetUploadUrl,
// 	getLatestPatientExam,
// 	selectLatestCameraImages,
// } from "../../appStore";
// import _ from "lodash";
// import axios from "axios";
// import { PlusOutlined } from "@ant-design/icons";
// import { Button, Modal, Upload } from "antd";
// import type { RcFile, UploadProps } from "antd/es/upload";
// import type { UploadFile } from "antd/es/upload/interface";
// import React, { useState, useEffect } from "react";
// import { IMAGE_PREVIEW_URL } from "../../utils";
// import { AXIOS_CLIENT } from "../../appStore/axiosInstance";
// import FilePreview from "./FilePreview";
// import { toast } from "react-toastify";

// const getBase64 = (file: RcFile): Promise<string> =>
// 	new Promise((resolve, reject) => {
// 		const reader = new FileReader();
// 		reader.readAsDataURL(file);
// 		reader.onload = () => resolve(reader.result as string);
// 		reader.onerror = (error) => reject(error);
// 	});

// const App = ({ camera, patientID }: { camera?: boolean; patientID?: string }) => {
// 	const [previewOpen, setPreviewOpen] = useState(false);
// 	const [previewImage, setPreviewImage] = useState("");
// 	const [previewTitle, setPreviewTitle] = useState("");
// 	const [cameraImages, setCameraImages] = useState<any[]>([]);
// 	const [fileList, setFileList] = useState<UploadFile[]>([]);
// 	const token = useAppSelector(selectLoggedInUser).token;
// 	const shouldUpload = useAppSelector(selectFileUploadStatus);
// 	const url = useAppSelector(selectFileUploadUrl);
// 	const remidioImages = useAppSelector(selectLatestCameraImages);

// 	useEffect(() => {
// 		let arr: any[] = [];

// 		patientID &&
// 			remidioImages?.forEach(async (file: any) => {
// 				// let data = await AXIOS_CLIENT.get(`${IMAGE_PREVIEW_URL}${file?.doc_key}`);
// 				// let originFileObj = new File([await data.data], file?.filename);
// 				arr.push({
// 					name: `${file?.laterality}`,
// 					fileName: `${file?.laterality} ${patientID}`,
// 					url: `${file?.path}`,
// 					preview: `${file?.thumbnailPath}`,
// 					status: "done",
// 					thumbUrl: `${file?.thumbnailPath}`,
// 					// originFileObj,
// 				});
// 			});

// 		setCameraImages(arr);
// 	}, [remidioImages]);

// 	useEffect(() => {
// 		if (patientID && camera && cameraImages.length) {
// 			setFileList((prev) => {
// 				let newImages = cameraImages.filter((file) => {
// 					let index = prev.findIndex((finder) => (finder.url = file.url));

// 					return index == -1 ? true : false;
// 				});
// 				return [...prev, ...newImages];
// 			});
// 		}
// 	}, [cameraImages]);

// 	const dispatch = useAppDispatch();

// 	const upload = async () => {
// 		let remidioImages = fileList.map((file) => {
// 			if (file.preview) {
// 				return file.id;
// 			}
// 		});

// 		fileList.forEach((file, index) => {
// 			file.status = "uploading";

// 			// uploadFile()
// 			// if (file.originFileObj && !file.preview)
// 			// 	uploadFile(file.originFileObj, token, url)
// 			// 		?.then((res) => {
// 			// 			setFileList((prev) => {
// 			// 				let files = [...prev];
// 			// 				files[index].status = "done";
// 			// 				return files;
// 			// 			});
// 			// 		})
// 			// 		.catch((err) => {
// 			// 			setFileList((prev) => {
// 			// 				let files = [...prev];
// 			// 				files[index].status = "error";
// 			// 				return files;
// 			// 			});
// 			// 		});
// 			// else if (file.preview) {
// 			// 	file.status = "done";
// 			// }
// 		});
// 	};

// 	useEffect(() => {
// 		dispatch(setUploadFile(false));
// 		dispatch(unsetUploadUrl());
// 	}, []);

// 	useEffect(() => {
// 		shouldUpload &&
// 			upload().finally(() => {
// 				dispatch(setUploadFile(false));
// 				dispatch(unsetUploadUrl());
// 			});
// 	}, [shouldUpload]);

// 	const handleCancel = () => setPreviewOpen(false);

// 	const handlePreview = async (file: UploadFile) => {
// 		if (!file.url && !file.preview) {
// 			file.preview = await getBase64(file.originFileObj as RcFile);
// 		}

// 		setPreviewImage(file.preview as string);
// 		setPreviewOpen(true);
// 		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1));
// 	};

// 	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
// 		newFileList.forEach((file) => (file.status = "done"));
// 		setFileList(newFileList);
// 	};

// 	return (
// 		<div>
// 			<Upload
// 				listType="picture-card"
// 				fileList={fileList}
// 				onPreview={handlePreview}
// 				onChange={handleChange}
// 				beforeUpload={() => {
// 					return false;
// 				}}>
// 				{fileList.length >= 8 ? null : (
// 					<div>
// 						<PlusOutlined />
// 						<div style={{ marginTop: 8 }}>Upload</div>
// 					</div>
// 				)}
// 			</Upload>
// 			<FilePreview openState={previewOpen} title={previewTitle} onCancel={handleCancel} previewImage={previewImage} />

// 			{camera && patientID && <Button onClick={() => dispatch(getLatestPatientExam(patientID))}>Get from Camera</Button>}
// 		</div>
// 	);
// };

// export default App;

// const uploadFile = async (id: string, files?: File[], remidioIds?: string[]) => {
// 	try {
// 		let response = await AXIOS_CLIENT.put(`bookings/updateBookingImages/${id}`, {
// 			images: files,
// 			remidioImgIDs: remidioIds,
// 		});
// 		console.log(response.status);
// 	} catch (err) {
// 		console.log(err);
// 		toast.error("Images failed to Upload", { containerId: "toast" });
// 	}

// 	// 			const formData = new FormData();
// 	// 		// files && formData.append("images" , files)
// 	// 		formData.append("remidioImgIDs", remidioIds)

// 	// fetch(`bookings/updateBookingImages/${id}`, {
// 	// 	headers: {
// 	// 					"Access-Control-Max-Age": "8000",
// 	// 					Authorization: `Bearer ${token}`,
// 	// 					accept: "*/*",
// 	// 				},
// 	// 				body: {
// 	// 					images: files,
// 	// 		remidioImgIDs: remidioIds,
// 	// 				}
// 	// })
// 	// if (url) {
// 	// 	let onProgress: any;
// 	// 	return new Promise((res, rej) => {
// 	// 		const xhr = new XMLHttpRequest();
// 	// 		xhr.open("POST", url);
// 	// 		xhr.withCredentials = true;
// 	// 		xhr.setRequestHeader("Access-Control-Max-Age", "9000");
// 	// 		xhr.setRequestHeader("Authorization", `Bearer ${token}`);
// 	// 		xhr.setRequestHeader("accept", "*/*");
// 	// 		xhr.onload = () => {
// 	// 			const response = JSON.parse(xhr.responseText);
// 	// 			res(response);
// 	// 		};
// 	// 		xhr.onerror = (event) => rej(event);
// 	// 		const formData = new FormData();
// 	// 		files.map((file) => {
// 	// 			formData.append("files", file);
// 	// 		});
// 	// 		xhr.send(formData);
// 	// 	});
// 	// }
// };
