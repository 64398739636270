import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const selectAllBookings = createSelector(
  [(state: RootState) => state?.bookings?.all],
  (bookings) => bookings
);

export const selectAllVisits = createSelector(
  [(state: RootState) => state?.visits?.all],
  (visits) => visits
);
export const selectActiveVisit = createSelector(
  [(state: RootState) => state?.visits?.active],
  (active) => active
);
export const selectLatestVisit = createSelector(
  [(state: RootState) => state?.visits?.latest],
  (active) => active
);

// select single by param
export const selectVisitByRef = createSelector(
  [selectAllVisits, (state: RootState, ref) => ref],
  (visits, ref) => visits?.find((item) => item?.bookingRefNumber == ref)
);
export const selectVisitById = createSelector(
  [selectAllVisits, (state: RootState, id) => id],
  (visits, id) => visits?.find((item) => item?.id == id)
);
export const selectVisitByPatientID = createSelector(
  [selectAllVisits, (state: RootState, id) => id],
  (visits, id) => visits?.find((item) => item?.patientIdNumber == id)
);

// select visits by filter param

export const selectVisitsByTechnician = createSelector(
  [selectAllVisits, (state: RootState, tech) => tech],
  (visits, tech) => visits?.filter((item) => item?.technician == tech)
);

export const selectVisitsByDoctor = createSelector(
  [
    selectAllVisits,
    selectAllBookings,
    (state: RootState, username) => username,
  ],
  (visits, bookings, username) =>
    bookings
      .filter((booking) => booking.doctor == username)
      .map((booking) =>
        visits.find((visit) => visit?.bookingRefNumber == booking.bookingRef)
      )
      .filter((booking) => booking)
);
