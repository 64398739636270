import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectAllPlatformUsers = createSelector(
	[(state: RootState) => state?.platformUsers.all],
	(users) => users
);
export const selectActivePlatformUser = createSelector(
	[(state: RootState) => state.platformUsers.active],
	(active) => active
);
export const selectLatestPlatformUser = createSelector(
	[(state: RootState) => state.platformUsers.latest],
	(active) => active
);

// select single by param
export const selectPlatformUsersByUsername = createSelector(
	[selectAllPlatformUsers, (state: RootState, username) => username],
	(users, username) => users.find((item) => item.username == username)
);
export const selectPlatformUsersById = createSelector(
	[selectAllPlatformUsers, (state: RootState, id) => id],
	(users, id) => users.find((item) => item.id == id)
);
export const selectAllDoctors = createSelector([selectAllPlatformUsers], (users) =>
	users.filter((user) => user.roles.includes("ROLE_DOCTOR"))
);
export const selectAllTechnicians = createSelector([selectAllPlatformUsers], (users) =>
	users.filter((user) => user.roles.includes("ROLE_TECHNICIAN"))
);
export const selectAllAdmins = createSelector([selectAllPlatformUsers], (users) =>
	users.filter((user) => user.roles.includes("ROLE_ADMIN"))
);
export const selectFirstGraders = createSelector([selectAllPlatformUsers], (users) =>
	users.filter((user) => user.roles.includes("FIRST_GRADER"))
);
export const selectSecondGraders = createSelector([selectAllPlatformUsers], (users) =>
	users.filter((user) => user.roles.includes("SECOND_GRADER"))
);
