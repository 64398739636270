import React, { useEffect, useState } from "react";
import { NavLink, Route } from "react-router-dom";
import { authenticate, changePassword, login, selectLoggedInUser, useAppDispatch, useAppSelector } from "../appStore";
import { Logo } from "../components";

const Login = ({ match }: { match: { params: any } }) => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectLoggedInUser);
	const username = match.params.id;

	useEffect(() => {
		if (user.isAuthenticated) window.location.href = window.location.origin + "/main";
	}, [user.isAuthenticated]);

	useEffect(() => {
		if (user.isLoggedIn) dispatch(authenticate());
	}, [user.isLoggedIn]);

	return (
		<div className="App">
			<div className="appAside" />
			<div className="appForm">
				<Logo />
				<h2 className="formTitle mt-3">Change Your Password</h2>
				<LoginForm username={username} />
			</div>
		</div>
	);
};

export const LoginForm = ({ username }: { username: string }) => {
	const dispatch = useAppDispatch();

	const [form, setForm] = useState({
		username,
		oldPassword: "",
		newPassword: "",
	});
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
	};

	return (
		<div className="">
			<div className="formCenter">
				<form
					className="formFields"
					onSubmit={(e) => {
						e.preventDefault();
						dispatch(changePassword(form));
					}}>
					{!username ? (
						<div className="formField">
							<label className="formFieldLabel" htmlFor="username">
								enter your username (sent in email)
							</label>
							<input
								autoComplete="off"
								type="text"
								name="username"
								onChange={handleChange}
								className="formFieldInput"
								placeholder="Enter your username"
								value={form.username}
							/>
						</div>
					) : null}
					<div className="formField">
						<label className="formFieldLabel" htmlFor="oldPassword">
							enter your old Password (sent in email)
						</label>
						<input
							autoComplete="off"
							type="text"
							name="oldPassword"
							onChange={handleChange}
							className="formFieldInput"
							placeholder="Enter your old password"
							value={form.oldPassword}
						/>
					</div>

					<div className="formField">
						<label className="formFieldLabel" htmlFor="newPassword">
							enter your new Password
						</label>
						<input
							autoComplete="off"
							type="password"
							name="newPassword"
							onChange={handleChange}
							className="formFieldInput"
							placeholder="Enter your new password"
							value={form.newPassword}
						/>
					</div>

					<div className="formField">
						<button className="formFieldButton">Sign In</button>{" "}
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
