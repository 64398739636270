import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VisitDataType, DataSliceType } from "../../../utils";
import { createVisit, getVisits, updateVisit, deleteVisit } from "../";

const initialState: DataSliceType<VisitDataType> = {
  latest: null,
  active: {
    attachmentInterpretation: "",
    bookingRefNumber: "",
    date_opened: "",
    diagnosis: "",
    doctorRecommendation: "",
    id: 0,
    patientFileNumber: "",
    patientIdNumber: "",
    patientInternalId: "",
    physicalExamination: "",
    technician: "",
  },
  all: [],
};

const Slice = createSlice({
  name: "visits",
  initialState,
  reducers: {
    setLatestVisit: (state, action: PayloadAction<VisitDataType>) => {
      state.latest = action.payload;
    },
    updateActiveVisit: (
      state,
      action: PayloadAction<{
        name: string;
        value: string | number;
      }>
    ) => {
      //  @ts-ignore
      state.active[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisits.fulfilled, (state, action) => {
        if (action.payload) state.all = action.payload;
        return state;
      })
      .addCase(createVisit.fulfilled, (state, action) => {
        if (action.payload) {
          state.latest = action.payload;
          state.active = initialState.active;
          state.all.push(action.payload);
        }
      })
      .addCase(updateVisit.fulfilled, (state, action) => {
        if (action.payload) {
          state.latest = action.payload;
          let index = state.all.findIndex(
            (item) => item.id === action.payload.id
          );
          state.all[index] = action.payload;
        }
      })
      .addCase(deleteVisit.fulfilled, (state, action) => {
        if (action.payload) {
          state.all = state.all.filter((item) => item.id !== action.payload);
          state.latest = initialState.latest;
        }
      });
  },
});

export const { setLatestVisit, updateActiveVisit } = Slice.actions;

export default Slice.reducer;
