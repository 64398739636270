import { useState, useEffect } from "react";
import Select, { ActionMeta, Options, SingleValue } from "react-select";
import { option } from "../../utils";

type Props = {
	name: string;
	placeholder?: string;
	value?: any;
	onChange?: (e: SingleValue<option>, a: ActionMeta<option>) => void;
	options: any[];
	keys?: {
		label?: string;
		value: string;
	};
	label?: string;
};
const SelectSearch = (props: Props) => {
	const [optionsArr, setOptionsArr] = useState<Options<option>>([]);

	const createOption = (option: any) => {
		if (props.keys)
			return {
				value: option[props.keys?.value],
				label: props.keys?.label ? option[props.keys?.label] : option[props.keys?.value],
			};
		else {
			return {
				value: option.value || "",
				label: option.label || option.value || "",
			};
		}
	};

	useEffect(() => {
		setOptionsArr(
			props.options.map((option) => {
				return createOption(option);
			})
		);
	}, [props.options]);
	return (
		<div className="col-md-4">
			<label className="mb-2 ">{props.label}</label>
			<Select
				options={[{ value: "", label: "----" }, ...optionsArr]}
				placeholder={props.placeholder}
				onChange={props.onChange}
				name={props.name}
				isClearable={true}
				defaultValue={{ value: "", label: "----" }}
				styles={{}}
			/>
		</div>
	);
};

export default SelectSearch;
