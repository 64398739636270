import { createSlice, current } from "@reduxjs/toolkit";
import { UserDataType } from "../../../utils";
import { login, authenticate } from "..";

type initialProps = UserDataType & {
	isLoggedIn: boolean;
	token: string;
	isAuthenticated: boolean;
};

const initialState: initialProps = {
	id: 0,
	landline: "",
	fullNames: "",
	mobileNumber: "",
	status: 0,
	isLoggedIn: false,
	username: "",
	email: "",
	token: "",
	isAuthenticated: false,
	roles: [],
	clinicId: {
		address: "string",
		id: 0,
		name: "string",
	},
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		signout: () => {
			// deliberately left empty
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.fulfilled, (state, action) => {
				if (action.payload) {
					state.token = action.payload;
					state.isLoggedIn = true;
				}
			})
			.addCase(authenticate.fulfilled, (state, action) => {
				if (action.payload) {
					state.isAuthenticated = true;
					state = Object.assign(state, action.payload);
				}
				return state;
			});
	},
});

export const { signout } = userSlice.actions;

export default userSlice.reducer;
