import React from "react";

const Title = (props: { text: string }) => {
	return (
		<h1
			style={{
				fontSize: "24px",
				fontWeight: 600,
			}}>
			{props.text}
		</h1>
	);
};

export default Title;
