import React, { useState, useEffect } from "react";
import {
	createClinic,
	createCountry,
	createDocumentTypes,
	createRelationships,
	createTitle,
	useAppDispatch,
} from "../../appStore";

import { Button, Form, InputText, Textarea, Select } from "../../components";

const initialState = {
	clinic: {
		name: "",
		code: "",
		address: "",
		contact: "",
		email: "",
	},
	country: {
		name: "",
		code: "",
		iso3: "",
		callingCode: "",
	},
	other: {
		name: "",
		code: "",
	},
};

const CreateNewClinic = () => {
	const dispatch = useAppDispatch();
	const [clinic, setClinic] = useState(initialState.clinic);

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		setter: any,
		obj: { [index: string]: string | number }
	) => {
		const { name, value } = e.target;
		setter({ ...obj, [name]: value });
	};
	const handleChangeTextArea = (
		e: React.ChangeEvent<HTMLTextAreaElement>,
		setter: any,
		obj: { [index: string]: string | number }
	) => {
		const { name, value } = e.target;
		setter({ ...obj, [name]: value });
	};

	return (
		<div className="show">
			<h3>Super Admin : Create New Clinic</h3>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					dispatch(createClinic(clinic));
					setClinic(initialState.clinic);
				}}>
				<InputText
					name="name"
					value={clinic.name}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleChange(e, setClinic, clinic);
					}}
					label="Name"
				/>
				{/* <InputText
					name="code"
					value={clinic.code}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleChange(e, setClinic, clinic);
					}}
					label="Code"
				/> */}
				{/* <InputText
					name="contact"
					value={clinic.contact}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleChange(e, setClinic, clinic);
					}}
					label="Contact"
				/> */}
				{/* <InputText
					name="email"
					value={clinic.email}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleChange(e, setClinic, clinic);
					}}
					label="Email"
				/> */}
				<Textarea
					name="address"
					value={clinic.address}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
						handleChangeTextArea(e, setClinic, clinic);
					}}
					label="Address"
				/>
				<Button color="primary" type="submit" text="Submit" />
			</Form>
		</div>
	);
};
export default CreateNewClinic;
