import { AutoComplete, Input } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { Search } from "react-bootstrap-icons";
import React, { useRef } from "react";

type Props = {
	onSubmit?: (e: React.FormEvent) => void;
	name?: string;
	id?: string;
	placeholder?: string;
	value?: string | number | readonly string[];
	onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
	onClick?: () => void;
	text?: string;
	formtext?: string;
	options?: any[];
};

const SearchBar = (props: Props) => {
	const form = useRef<HTMLFormElement>(null);
	return (
		<form onSubmit={props.onSubmit} className="col-md-6 mt-4" ref={form}>
			<div className="input-group">
				<input
					type="search"
					name={props.name}
					id={props.id}
					value={props.value}
					placeholder={props.placeholder}
					onChange={props.onChange}
					className="form-control "
				/>

				<button type="submit" className="btn alt-pink" onClick={props.onClick}>
					{props.text ? props.text : <Search />}
				</button>
			</div>

			{/* <AutoComplete options={props.options}>
        <Input.Search
          style={{ width: 400, maxWidth: "90%" }}
          name={props.name}
          id={props.id}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onSearch={() => form.current?.requestSubmit()}
        ></Input.Search>
      </AutoComplete> */}
			<p className="text-muted mt-1">{props.formtext}</p>
		</form>
	);
};

export default SearchBar;
