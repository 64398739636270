import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  MedicationBasicType,
  PatientDataType,
  Prescription,
} from "../../../utils";
import { AXIOS_CLIENT } from "../../axiosInstance";
import { store } from "../../store";
import { newPrescriptionNotification } from "../notificationSlice/thunks";

export const getPrescription = createAsyncThunk(
  "prescription/fetchAll",
  async () => {
    try {
      let response = await AXIOS_CLIENT.get("medical/medications/all");
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

export const createPrescription = createAsyncThunk(
  "prescription/createNew",
  async (
    {
      alert = false,
      patientInfo,
      bookingRef,
    }: {
      alert?: boolean;
      patientInfo?: PatientDataType;
      bookingRef?: string;
    },
    { getState }
  ) => {
    let prescription = store.getState().medicals.prescriptions.active;
    try {
      let data = () => {
        if (patientInfo) {
          return prescription.map((item) => ({
            ...item,
            patientBasicInfo: patientInfo,
          }));
        }
        if (bookingRef) {
          return prescription.map((item) => ({
            ...item,
            bookingRef,
          }));
        }

        // send notification if it is a stand alone prescription
        patientInfo && store.dispatch(newPrescriptionNotification(patientInfo));

        // return
        return prescription;
      };

      let response = await AXIOS_CLIENT.post(`medical/medications`, data());
      alert &&
        toast.success("prescription created successfully", {
          containerId: "toast",
        });
      if (patientInfo) {
        window.location.href =
          window.location.origin +
          `/main/medical/file/${patientInfo?.idNumber}`;
      }
      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!err.response) {
        throw err;
      }
      alert &&
        toast.error(error.response?.data.message, { containerId: "toast" });
    }
  }
);
