import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	selectLoggedInUser,
	signout,
	useAppDispatch,
	useAppSelector,
	toggleSidebar as toggleSidebarAction,
} from "../../appStore";
import { useToggle } from "../../utils";
import { Bell, QuestionCircle, List, CaretDownFill } from "react-bootstrap-icons";
import { BrandName } from "../";

const Navbar = () => {
	const dispatch = useAppDispatch();
	const toggleSidebar = () => {
		dispatch(toggleSidebarAction());
	};

	return (
		<nav className="navbar px-1">
			<button onClick={toggleSidebar} className="menu-toggler">
				<List style={{ display: "block" }} />
			</button>
			<div className="nav-brand">
				<BrandName />
			</div>

			<div className="">
				<Link to="/main/notifications" className="ps-1">
					<Bell />
				</Link>

				<Link to="/main/help" className="ps-2">
					<QuestionCircle />
				</Link>

				<UserInfo />
			</div>
		</nav>
	);
};

const UserInfo = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectLoggedInUser);
	const [istoggled, setIsToggled] = useState(false);

	return (
		<>
			<div className="btn-group ps-2">
				<Link to="/main" className="pt-1">
					{user.username}
				</Link>
				<button
					className="btn"
					onClick={() => {
						setIsToggled((prev) => !prev);
					}}>
					{" "}
					<CaretDownFill />
				</button>
			</div>
			<ul className="mt-5 dropdown-card" style={istoggled ? { display: "block" } : { display: "none" }}>
				<li className="dropdown-item">{user.email}</li>
				<li className="dropdown-item">
					<Link to="/main">Dashboard</Link>{" "}
				</li>
				<li className="dropdown-item">
					<Link to="/main/notifications">Notifications</Link>
				</li>
				<li className="dropdown-item">
					<Link to="/main/help">Help</Link>
				</li>
				<li className="dropdown-item">
					<button onClick={(e) => dispatch(signout())} className="btn alt-lime">
						Logout
					</button>{" "}
				</li>
			</ul>
		</>
	);
};

export const MenuToggle = () => {
	return (
		<List
			size={40}
			id="main-nav-menu-button"
			onClick={() => {
				let nav = document.getElementById("main-nav-menu");
				nav?.classList.toggle("open");
			}}
		/>
	);
};

export default Navbar;
