import React from "react";
import { NavLink } from "react-router-dom";

const ChoiceBar = (props: {
  link1?: string;
  text1?: string;
  link2?: string;
  text2?: string;
  link3?: string;
  text3?: string;
  link4?: string;
  text4?: string;
  link5?: string;
  text5?: string;
  link6?: string;
  text6?: string;
}) => {
  return (
    <div>
      <div className="mb-2 row text-center">
        {props.link1 && <ChoiceButton link={props.link1} text={props.text1} />}
        {props.link2 && <ChoiceButton link={props.link2} text={props.text2} />}
        {props.link3 && <ChoiceButton link={props.link3} text={props.text3} />}
        {props.link4 && <ChoiceButton link={props.link4} text={props.text4} />}
        {props.link5 && <ChoiceButton link={props.link5} text={props.text5} />}
        {props.link6 && <ChoiceButton link={props.link6} text={props.text6} />}
      </div>
    </div>
  );
};

const ChoiceButton = (props: { link: string; text: string | undefined }) => {
  return (
    <NavLink
      to={props.link}
      activeClassName="border-bottom border-3"
      className="col py-3"
      exact
    >
      {props.text}
    </NavLink>
  );
};

export default ChoiceBar;
