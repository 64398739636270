import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectAllClinics = createSelector(
  [(state: RootState) => state?.resources.clinics],
  (clinics) => clinics
);
export const selectAllTitles = createSelector(
  [(state: RootState) => state?.resources.titles],
  (titles) => titles
);
export const selectAllCountries = createSelector(
  [(state: RootState) => state?.resources.countries],
  (countries) => countries
);
export const selectAllDocumentTypes = createSelector(
  [(state: RootState) => state?.resources.document_types],
  (document_types) => document_types
);
export const selectAllRelationshipTypes = createSelector(
  [(state: RootState) => state?.resources.relationship_types],
  (relationship_types) => relationship_types
);
// select single by name
export const selectClinicByName = createSelector(
  [selectAllClinics, (state: RootState, name) => name],
  (clinics, name) => clinics.find((item) => item.name == name)
);
// select single by code
export const selectClinicByCode = createSelector(
  [selectAllClinics, (state: RootState, code) => code],
  (clinics, code) => clinics.find((item) => item.code == code)
);
// select single by name
export const selectCountryByName = createSelector(
  [selectAllCountries, (state: RootState, name) => name],
  (countries, name) => countries.find((item) => item.name == name)
);
// select single by code
export const selectCountryByCode = createSelector(
  [selectAllCountries, (state: RootState, code) => code],
  (countries, code) => countries.find((item) => item.code == code)
);
// select single by name
export const selectTitleByName = createSelector(
  [selectAllTitles, (state: RootState, name) => name],
  (titles, name) => titles.find((item) => item.name == name)
);
// select single by code
export const selectTitleByCode = createSelector(
  [selectAllTitles, (state: RootState, code) => code],
  (titles, code) => titles.find((item) => item.code == code)
);
// select single by name
export const selectRelationshipsTypeByName = createSelector(
  [selectAllRelationshipTypes, (state: RootState, name) => name],
  (relation, name) => relation.find((item) => item.name == name)
);
// select single by code
export const selectRelationshipsTypeByCode = createSelector(
  [selectAllRelationshipTypes, (state: RootState, code) => code],
  (relation, code) => relation.find((item) => item.code == code)
);
// select single by name
export const selectDocumentTypeByName = createSelector(
  [selectAllDocumentTypes, (state: RootState, name) => name],
  (documents, name) => documents.find((item) => item.name == name)
);
// select single by code
export const selectDocumentTypeByCode = createSelector(
  [selectAllDocumentTypes, (state: RootState, code) => code],
  (documents, code) => documents.find((item) => item.code == code)
);
