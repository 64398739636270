import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectFileUploadStatus = createSelector(
	[(state: RootState) => state?.fileUpload?.uploadFile],
	(files) => files
);
export const selectFileUploadUrl = createSelector(
	[(state: RootState) => state?.fileUpload?.uploadUrl],
	(files) => files
);
export const selectFileUploadID = createSelector([(state: RootState) => state?.fileUpload?.uploadID], (files) => files);

export const selectCurrentBookingImages = createSelector(
	[(state: RootState) => state?.fileUpload.currentImages],
	(files) => files
);
