import React from "react";

const Fieldset = ({ children, disabled }: { children: React.ReactNode; disabled?: boolean }) => {
	return (
		<fieldset className="row gy-4 gx-4" disabled={disabled}>
			{children}
		</fieldset>
	);
};

export default Fieldset;
