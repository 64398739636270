import React from "react";
import Card from "./Card";
type Props = {
	heading?: string;
	children?: React.ReactNode;
};

const LargeCard = ({ heading, children }: Props) => {
	return (
		<div
			className="table-container"
			style={{
				padding: "20px",
				borderRadius: "2px",
				backgroundColor: "white",
				border: "var(--light-border)",
				boxShadow: "var(--box-shadow-1)",
				gap: "10px",
				width: "550px",
				maxWidth: "90vw",
			}}>
			<h2 className="h6 text-uppercase">{heading}</h2>
			{children}
		</div>
	);
};

export default LargeCard;
