import React, { HTMLProps } from "react";

const InputVariable = (props: HTMLProps<HTMLInputElement> & { label: string; formtext?: string }) => {
	return (
		<div className="col-md-4">
			<label className="form-label">{props.label}</label>
			<input {...props} className="form-control" />
			<p className="form-text">{props.formtext}</p>
		</div>
	);
};

export default InputVariable;
