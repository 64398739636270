import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  ResourceDataType,
  CountryDataType,
  ResourceType,
  ClinicDataType,
} from "../../../utils";
import { AXIOS_CLIENT } from "../../axiosInstance";

/**
 * clinics
 */
export const getAllClinics = createAsyncThunk(
  "resources/clinic/fetchAll",
  async () => {
    let response = await AXIOS_CLIENT.get("resources/clinics");
    return response.data;
  }
);

export const createClinic = createAsyncThunk(
  "resources/clinic/createNew",
  async (clinicData: ClinicDataType) => {
    try {
      let response = await AXIOS_CLIENT.post("resources/clinics", clinicData);
      toast.success("Clinic created successfully", { containerId: "toast" });
      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!err.response) {
        throw err;
      }
      toast.error(error.response?.data.message, { containerId: "toast" });
    }
  }
);

/**
 * titles
 */
export const getAllTitles = createAsyncThunk(
  "resources/title/fetchAll",
  async () => {
    let response = await AXIOS_CLIENT.get("resources/titles");
    return response.data;
  }
);

export const createTitle = createAsyncThunk(
  "resources/title/createNew",
  async (titleData: ResourceDataType) => {
    try {
      let response = await AXIOS_CLIENT.post("resources/titles", titleData);
      toast.success("Title created successfully", { containerId: "toast" });
      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!err.response) {
        throw err;
      }
      toast.error(error.response?.data.message, { containerId: "toast" });
    }
  }
);

/**
 * relationships
 *
 */
export const getAllRelationships = createAsyncThunk(
  "resources/relationship/fetchAll",
  async () => {
    let response = await AXIOS_CLIENT.get("resources/relationship-types");
    return response.data;
  }
);
export const createRelationships = createAsyncThunk(
  "resources/relationship/createNew",
  async (relationshipData: ResourceDataType) => {
    try {
      let response = await AXIOS_CLIENT.post(
        "resources/relationship-types",
        relationshipData
      );
      toast.success("Relationship type created successfully", {
        containerId: "toast",
      });
      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!err.response) {
        throw err;
      }
      toast.error(error.response?.data.message, { containerId: "toast" });
    }
  }
);

/**
 * documents
 */
export const getAllDocumentTypes = createAsyncThunk(
  "resources/documents/fetchAll",
  async () => {
    let response = await AXIOS_CLIENT.get("resources/document-types");
    return response.data;
  }
);

export const createDocumentTypes = createAsyncThunk(
  "resources/documents/createNew",
  async (documentsData: ResourceDataType) => {
    try {
      let response = await AXIOS_CLIENT.post(
        "resources/document-types",
        documentsData
      );
      toast.success("Document type created successfully", {
        containerId: "toast",
      });
      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!err.response) {
        throw err;
      }
      toast.error(error.response?.data.message, { containerId: "toast" });
    }
  }
);
/**
 * countries
 */
export const getAllCountries = createAsyncThunk(
  "resources/country/fetchAll",
  async () => {
    let response = await AXIOS_CLIENT.get("resources/countries");
    return response.data;
  }
);

export const createCountry = createAsyncThunk(
  "resources/country/createNew",
  async (countryData: CountryDataType) => {
    try {
      let response = await AXIOS_CLIENT.post("resources/country", countryData);
      toast.success("Country created successfully", { containerId: "toast" });
      return response.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!err.response) {
        throw err;
      }
      toast.error(error.response?.data.message, { containerId: "toast" });
    }
  }
);

/**
 * delete any
 */

export const deleteResource = createAsyncThunk(
  "resources/delete",
  async ({ name, type }: { name: string; type: ResourceType }) => {
    let response = await AXIOS_CLIENT.delete(`resources/${name}`);
    return { name, type };
  }
);
