import React from "react";
import Logo from "./Logo";
import { ArrowRepeat } from "react-bootstrap-icons";

const Loader = (props: { logo?: boolean }) => {
  return (
    <div className="spinner-container ">
      <div className=" position-absolute start-50 top-50 translate-middle text-center">
        {props.logo && <Logo />}
        <ArrowRepeat className="spinner" />
      </div>
    </div>
  );
};

export default Loader;
