import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export const selectAllPatients = createSelector(
  [(state: RootState) => state?.patients.all],
  (patients) => patients
);
export const selectActivePatient = createSelector(
  [(state: RootState) => state.patients.active],
  (active) => active
);
export const selectLatestPatient = createSelector(
  [(state: RootState) => state.patients.latest],
  (active) => active
);

// select single by param
export const selectPatientByIdNumber = createSelector(
  [selectAllPatients, (state: RootState, IdNumber: string) => IdNumber],
  (patients, IdNumber) => patients?.find((item) => item?.idNumber == IdNumber)
);
export const selectPatientById = createSelector(
  [selectAllPatients, (state: RootState, id) => id],
  (patients, id) => patients.find((item) => item.id == id)
);
