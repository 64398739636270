import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ProtectedRoute, Loader } from "./components";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";
// import Change from "./components/maincomponents/ChangePassword";
// import NothingHere from "./components/minorcomponents/pages/NothingHere";

import Main from "./pages/Main";
import Login from "./pages/Login";
import SuperAdmin from "./pages/superAdmin";
import ChangePassword from "./pages/ChangePassword";
import { useAppSelector, selectLoggedInUser } from "./appStore";
// import Test from "./pages/test";
const Home = lazy(() => import("./pages/NewHome"));
const About = lazy(() => import("./pages/About"));

export default function App() {
	const user = useAppSelector(selectLoggedInUser);
	let isAdmin = user.roles[0] === "ROLE_ADMIN";

	// Show an alert if the internet connection is lost
	useEffect(() => {
		let err;
		navigator.onLine == false
			? (err = toast.error("You have lost your internet connection", {
					position: "top-center",
					theme: "dark",
					containerId: "toast",
			  }))
			: toast.dismiss(err);
	}, [navigator.onLine]);

	return (
		<div>
			{
				<Router>
					<Switch>
						{/* <Route path="/change" component={Change} />
						 */}
						{/* Change to super admin after api update */}
						<ProtectedRoute path="/superadmin/dashboard" component={SuperAdmin} condition={isAdmin} />

						<ProtectedRoute path="/main" component={Main} condition={user.isAuthenticated} />
						{/* <Route path="/test" component={Test}></Route> */}
						<Route path="/login" component={Login} />
						<Route path="/change/:id" component={ChangePassword} />
						<Route path="/change" component={ChangePassword} />
						<Route path="/about">
							<Suspense fallback={<Loader logo />}>
								<About />
							</Suspense>
						</Route>
						<Route path="/">
							<Suspense fallback={<Loader logo />}>
								<Home />
							</Suspense>
						</Route>
						{/* <Route path="*" component={NothingHere} /> */}
					</Switch>
				</Router>
			}

			<ToastContainer
				position="bottom-center"
				limit={1}
				enableMultiContainer={true}
				containerId="image-preview"
				className="image-preview-container"
			/>

			<ToastContainer theme="colored" position="top-center" limit={4} enableMultiContainer={true} containerId="toast" />
		</div>
	);
}
