import React, { HTMLProps } from "react";

const SmallInput = (
	props: HTMLProps<HTMLInputElement> & {
		label: string;
		formtext?: string;
		unit?: string;
	}
) => {
	return (
		<div className="col-md-4">
			<label className="form-label">{props.label}</label>
			<input {...props} className={`form-control &{props.className}`} data-unit={props.unit} />
			<p className="form-text">{props.formtext}</p>
		</div>
	);
};

export default SmallInput;
