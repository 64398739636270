import React from "react";
type Props = {
	label?: string;
	name: string;
	value?: string | number | readonly string[];
	onChange?: (e?: React.ChangeEvent) => void;
	ref?: React.LegacyRef<HTMLSelectElement>;
	required?: boolean;
	children?: React.ReactNode;
};
const Select = (props: Props) => {
	return (
		<div className="col-md-4">
			<label className="form-label">{props.label}</label>
			<select
				name={props.name}
				className="form-select"
				value={props.value}
				onChange={props.onChange}
				ref={props.ref}
				required={props.required}>
				{props.children}
			</select>
		</div>
	);
};

export default Select;
