import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prescription, ISOTIME, MedicationBasicType } from "../../../utils";
import { createPrescription, getPrescription } from "./thunks";

type init = {
  prescriptions: {
    all: Partial<Prescription>;

    latest: Partial<Prescription> | null;
    active: Partial<MedicationBasicType>[];
  };
};

const initialState: init = {
  prescriptions: {
    all: [],
    latest: null,
    active: [
      {
        date_created: ISOTIME(),
        description: "",
        id: 9,
        title: "",
      },
    ],
  },
};

const medicalSlice = createSlice({
  name: "medicals",
  initialState,
  reducers: {
    setLatestPrescription: (state, action) => {
      if (action.payload) state.prescriptions.latest = action.payload;
    },
    addNewPrescription: (state) => {
      // @ts-ignore
      state.prescriptions.active.push(initialState.prescriptions.active[0]);
    },
    deleteSingleActivePrescription: (state, action: PayloadAction<number>) => {
      state.prescriptions.active.splice(action.payload, 1);
    },
    updateSingleActivePrescription: (
      state,
      action: PayloadAction<{ index: number; name: string; value: string }>
    ) => {
      // @ts-ignore
      state.prescriptions.active[action.payload.index][action.payload.name] =
        action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPrescription.fulfilled, (state, action) => {
        if (action.payload) {
          state.prescriptions.latest = action.payload;
          state.prescriptions.active = [];
        }
      })
      .addCase(getPrescription.fulfilled, (state, action) => {
        if (action.payload) {
          state.prescriptions.all = action.payload;
        }
      });
  },
});

export const {
  setLatestPrescription,
  addNewPrescription,
  deleteSingleActivePrescription,
  updateSingleActivePrescription,
} = medicalSlice.actions;

export default medicalSlice.reducer;
