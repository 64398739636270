import { BookingDataType, PatientDataType, VisitDataType } from "../types";

const filterBookings = (
  bookingData: BookingDataType[],
  patientData: PatientDataType[],
  visitData: VisitDataType[],
  rawquery: string
) => {
  let arr: string[] = [];

  visitData.forEach((v) => arr.push(v.bookingRefNumber));

  const query = rawquery.trim();

  return bookingData
    .filter((data) => {
      return data.patientNationalID === query || data.bookingRef === query;
    })
    .map((a) => {
      const patient = patientData.find(
        (b) => b.idNumber == query || b.idNumber == a.patientNationalID
      );
      return {
        ...a,
        ...patient,
        id: a.id,
        patientId: patient?.id,
      };
    })
    .filter((c) => {
      return !arr.includes(c.bookingRef);
    });
};

export default filterBookings;
