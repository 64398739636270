import { RouteComponentProps, useLocation } from "react-router-dom";
import { PatientFile } from "../../components";
import { selectLatestPrescription, selectPatientByIdNumber, useAppSelector } from "../../appStore";
const MedicalFile = ({ match }: RouteComponentProps) => {
	const params: { [index: string]: string } = match.params;
	const patient = useAppSelector((state) => selectPatientByIdNumber(state, params.id));
	const prescription = useAppSelector(selectLatestPrescription);

	return (
		<>
			<PatientFile patientData={patient} />
		</>
	);
};

export default MedicalFile;
