import React, { MouseEventHandler } from "react";

function useDrap<T extends HTMLElement>(
  ref: React.MutableRefObject<T>
): MouseEventHandler<T> {
  const mousedown: MouseEventHandler<T> = (
    e: React.MouseEvent<T, MouseEvent>
  ) => {
    let offsetX = e.clientX;
    let offsetY = e.clientY;

    const mousemove = (e: MouseEvent) => {
      let newX = offsetX - e.clientX;
      let newY = offsetY - e.clientY;

      const rect = ref.current.getBoundingClientRect();

      if (e.clientY >= 0 && e.clientX >= 0) {
        ref.current.style.top = rect.top - newY + "px";
        ref.current.style.left = rect.left - newX + "px";
      }

      offsetX = e.clientX;
      offsetY = e.clientY;
    };
    const mouseup = () => {
      window.removeEventListener("mousemove", mousemove);
      window.removeEventListener("mouseup", mouseup);
    };

    window.addEventListener("mousemove", mousemove);
    window.addEventListener("mouseup", mouseup);
  };

  return mousedown;
}

export default useDrap;
