import React, { Suspense } from "react";
import { Paper, Title, ChoiceBar, Sidebar } from "../../components";
import { Route } from "react-router-dom";
import AddUser from "./CreateNewUser";
import AllUsers from "./AllUsers";
import AllClinics from "./AllClinics";
import CreateNewClinic from "./CreateNewClinic";
// import UpdateUser from "./UpdateUser";

const SuperAdmin = () => {
	return (
		<div className="MainApp">
			<Suspense fallback={""}>
				<Sidebar isSuperAdmin />
			</Suspense>

			<div className="full-grid">
				<Paper className="container-full page-pad">
					<Route path="/superadmin/dashboard" exact component={AddUser} />
					<Route path="/superadmin/dashboard/users/all" component={AllUsers} />
					<Route path="/superadmin/dashboard/clinic/createnew" component={CreateNewClinic} />
					<Route path="/superadmin/dashboard/clinic/all" component={AllClinics} />
					{/* <Route path="/main/user/update" exact component={UpdateUser} /> */}
				</Paper>
			</div>
		</div>
	);
};

export default SuperAdmin;
