import { createSlice } from "@reduxjs/toolkit";
import {
  ClinicDataType,
  CountryDataType,
  ResourceDataType,
} from "../../../utils";
import {
  deleteResource,
  getAllClinics,
  getAllCountries,
  getAllDocumentTypes,
  getAllRelationships,
  getAllTitles,
  createClinic,
  createCountry,
  createDocumentTypes,
  createRelationships,
  createTitle,
} from "..";

const initialState: {
  clinics: ClinicDataType[];
  countries: CountryDataType[];
  document_types: ResourceDataType[];
  relationship_types: ResourceDataType[];
  titles: ResourceDataType[];
} = {
  clinics: [],
  countries: [],
  document_types: [],
  relationship_types: [],
  titles: [],
};

const Slice = createSlice({
  name: "visits",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllClinics.fulfilled, (state, action) => {
        if (action.payload) state.clinics = action.payload.clinicsDTOList;
        return state;
      })
      .addCase(createClinic.fulfilled, (state, action) => {
        action.payload && state.clinics?.push(action.payload);
        return state;
      })
      .addCase(getAllTitles.fulfilled, (state, action) => {
        if (action.payload) state.titles = action.payload.titleList;
        return state;
      })
      .addCase(createTitle.fulfilled, (state, action) => {
        action.payload && state.titles.push(action.payload);
        return state;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        if (action.payload) state.countries = action.payload.countryDTOList;
        return state;
      })
      .addCase(createCountry.fulfilled, (state, action) => {
        action.payload && state.countries.push(action.payload);
        return state;
      })
      .addCase(getAllDocumentTypes.fulfilled, (state, action) => {
        if (action.payload)
          state.document_types = action.payload.relationshipList;
        return state;
      })
      .addCase(createDocumentTypes.fulfilled, (state, action) => {
        action.payload && state.document_types.push(action.payload);
        return state;
      })
      .addCase(getAllRelationships.fulfilled, (state, action) => {
        if (action.payload)
          state.relationship_types = action.payload.relationshipList;
        return state;
      })
      .addCase(createRelationships.fulfilled, (state, action) => {
        action.payload && state.relationship_types.push(action.payload);
        return state;
      })
      .addCase(deleteResource.fulfilled, (state, action) => {
        if (action.payload) {
          state[action.payload.type] = state[action.payload.type].filter(
            (item) => item.name !== action.payload.name
          ) as any;
        }
      });
  },
});

export const {} = Slice.actions;

export default Slice.reducer;
