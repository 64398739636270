import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { UserDataType } from "../../../utils";
import { AXIOS_CLIENT, baseURL } from "../../axiosInstance";
import { toast } from "react-toastify";
import { store } from "../../store";
import { sendNewUserEmail } from "../notificationSlice/thunks";

export const getPlatformUsers = createAsyncThunk("users/fetchAll", async () => {
  let response = await AXIOS_CLIENT.get("users/");
  return response.data;
});

export const createPlatformUser = createAsyncThunk(
  "users/createNew",
  async (userData: Omit<UserDataType, "id" | "status">) => {
    try {
      await axios.post(`${baseURL}users/signup`, userData, {
        headers: {
          "Access-Control-Max-Age": 8000,
          accept: "*/*",
        },
      });
      let userInfo = await AXIOS_CLIENT.get(`users/${userData.username}`);
      toast.success("User created successfully", { containerId: "toast" });
      // send notification
      store.dispatch(sendNewUserEmail(userInfo.data));
      // return data
      return userInfo.data;
    } catch (err: any) {
      let error: AxiosError<any> = err;
      if (!error.response) {
        toast.error("error creating user", {
          containerId: "toast",
        });
        throw err;
      }
      toast.error(error.response.data.message as string, {
        containerId: "toast",
      });
    }
  }
);

export const getSinglePlatformUser = createAsyncThunk(
  "users/fetchSingle",
  async (username: string) => {
    let response = await AXIOS_CLIENT.get(`users/${username}`);
    return response.data;
  }
);

export const updatePlatformUser = createAsyncThunk(
  "users/update",
  async (userData: UserDataType) => {
    let response = await AXIOS_CLIENT.put(`users/${userData.username}`);
    return { ...response.data, id: userData.id };
  }
);

export const deletePlatformUser = createAsyncThunk(
  "users/delete",
  async (username: string) => {
    let response = await AXIOS_CLIENT.delete(`users/${username}`);
    return username;
  }
);
