import React from "react";

const Form = (props: { onSubmit: (e: React.FormEvent) => void; children: React.ReactNode }) => {
	return (
		<form className="full-form wide" onSubmit={props.onSubmit}>
			{props.children}
		</form>
	);
};

export default Form;
