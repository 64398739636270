import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  sidebarStatus: false,
  miniPlayer: false,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean | undefined>) => {
      state.sidebarStatus = action.payload
        ? action.payload
        : !state.sidebarStatus;
    },
    toggleMiniPlayer: (state, action: PayloadAction<boolean | undefined>) => {
      state.miniPlayer = action.payload ? action.payload : !state.miniPlayer;
    },
  },
});

export const { toggleMiniPlayer, toggleSidebar } = layoutSlice.actions;

export default layoutSlice.reducer;
