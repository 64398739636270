// export const GLOBAL_URL = "https://api2.alive.co.bw/actuator/health";
export const GLOBAL_URL = "https://aliveapp.azurewebsites.net";
export const FRONT_END_URL = "https://mpona.com";
// export const SMS_URL = "https://smsapi.me.co.bw";
export const SMS_URL =
	"https://app.mobilenterprises.com/index.php?app=ws&u=Alive&h=5fe5a1e77a97cdeb6d40ca3350857b73&op=pv&"; // append to={reciever number}&msg={message : spaces designated by %20}

export const IMAGE_PREVIEW_URL = "https://uat-alivebffstorage.s3.eu-west-1.amazonaws.com/";

// export const SMS_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzY0NzM3NjksInBhc3N3b3JkIjoiMzZBVkVsaSIsInVzZXJuYW1lIjoiQUxJVkUifQ.JTsbE9Dp3m5LdoS_uyBL0jT9uJ_HBjYYwJ08NCLR1NU`
// export const SMS_TOKEN = "5fe5a1e77a97cdeb6d40ca3350857b73"

export const ISOTIME = () => new Date().toISOString();

export const REGISTER_MAIL_HREF = `mailto:mponahealth@gmail.com?subject=Registration Request for Mpona Platform&body=Dear Mpona Team,%0D%0A
								We, [Your Clinic Name], are eager to register for your Eye Screening Platform to streamline our operations and provide better care for our patients.%0D%0A
								Please provide us with the necessary steps to complete the registration process.%0D%0A
								Looking forward to your prompt response.%0D%0A
								Best regards,%0D%0A
								[Your Full Name]%0D%0A
								[Your Position]%0D%0A
								%0D%0A
								Clinic Name: [Your Clinic Name]%0D%0A
								Clinic Email: [Your Clinic Email]%0D%0A
								Clinic Address: [Your Clinic Adresss]%0D%0A
								Clinic Phone Number: [Your Clinic Phone Number]`;
