import { BookingDataType, VisitDataType } from "../types";

const removeResolvedBookings = (
  bookingData: BookingDataType[],
  visitData: VisitDataType[]
) => {
  let arr: string[] = [];
  visitData.forEach((v) => arr.push(v.bookingRefNumber));

  return bookingData.filter((c) => {
    return !arr.includes(c.bookingRef);
  });
};

export default removeResolvedBookings;
