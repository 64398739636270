import { useEffect, useState } from "react";
import {
	getPlatformUsers,
	selectAllPatients,
	selectAllPlatformUsers,
	selectLoggedInUser,
	useAppDispatch,
	deletePlatformUser,
	useAppSelector,
} from "../../appStore";
import _ from "lodash";
import { AsyncTable } from "../../components";
import { PatientDataType, removeResolvedBookings, UserDataType } from "../../utils";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";

const initState = [
	{
		fullName: "",
		email: "",
		username: "",
		clinic: "",
		role: "",
		mobileNumber: "",
	},
];

const AllUsers = () => {
	const dispatch = useAppDispatch();
	const users = useAppSelector(selectAllPlatformUsers);
	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteID, setDeleteID] = useState("");
	const [TableData, setTableData] = useState<UserDataType[]>([]);

	useEffect(() => {
		dispatch(getPlatformUsers());
	}, []);

	useEffect(() => {
		let data = [];

		data = users.map((ab) => ({
			...ab,
			fullName: ab.fullNames,
			Clinic: ab?.clinicId?.name,
			MobileNumber: ab.mobileNumber,
			role: ab.roles[0].includes("GRADER") ? _.startCase(ab.roles[0]) : _.capitalize(ab.roles[0].slice(5)),
			actions: (
				<div className="d-flex gap-2 flex-wrap">
					{/* <Button type="link">
						<Link to={`/main/patient/update_form/${ab.username}`}>Edit</Link>
					</Button> */}
					<Button
						type="text"
						danger
						onClick={(e) => {
							setDeleteID(ab.username);
							setDeleteModal(true);
						}}>
						Delete
					</Button>
				</div>
			),
		}));

		setTableData(data);
	}, [users]);

	return (
		<>
			<h3>Super Admin : All Users</h3>
			<AsyncTable
				propData={TableData.length ? TableData : initState}
				disableSortList={["actions"]}
				disableLinkList={["actions"]}
				linked
			/>
			<Modal
				open={deleteModal}
				title="Delete Confirmation"
				onCancel={() => {
					setDeleteID("");
					setDeleteModal(false);
				}}
				okText="Yes, Delete"
				okButtonProps={{ danger: true }}
				onOk={() => {
					setDeleteModal(false);
					if (deleteID) {
						dispatch(deletePlatformUser(deleteID));
					}
				}}>
				<h6 className="text-danger">Are you sure you want to Delete this user?</h6>
			</Modal>
		</>
	);
};

export default AllUsers;
