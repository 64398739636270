import Paper from "./Paper";

const Help = () => {
  return (
    <Paper className="text-center">
      <h1 className="text-uppercase my-4">Help</h1>
      <p>Use the Sidebar to navigate the application</p>
      <p className="text-muted">
        Found a bug?{" "}
        <a href="mailto:mimiriath@gmail.com">Email the Developers</a>
      </p>

      <div className="mt-5">
        <h2 className="h4">How to use this application</h2>
      </div>
    </Paper>
  );
};

export default Help;
