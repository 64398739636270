import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { AXIOS_CLIENT } from "../../axiosInstance";

export const getBookingImages = createAsyncThunk("images/getBooking", async (id: number) => {
	try {
		let arr: any[] = [];
		let response = await AXIOS_CLIENT.get(`documents/download/${id}/for/BOOKING`);

		return response.data;
	} catch (err) {
		toast.error("Failed to fetch Images", {
			containerId: "toast",
		});
	}
});
