import { BookingDataType } from "../types";

const filterExpiredBookings = (bookingData: BookingDataType[]) => {
  let now = new Date().toISOString().split("T")[0].replace(/-/g, "");

  return bookingData.filter((a) => {
    return parseInt(a.dateBooked.replace(/-/g, "")) >= parseInt(now);
  });
};

export default filterExpiredBookings;
